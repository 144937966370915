////
/// @group customForms
////

$custom-forms-transition: background-color 0.15s ease-in-out,
	border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// Form File

$form-file: () !default;
$form-file: map-deep-merge(
	(
		border-radius: clay-enable-rounded($input-border-radius),
		display: flex,
		position: relative,
		transition: clay-enable-transitions($input-transition),
		focus-within: (
			background-color: $input-focus-bg,
			box-shadow: $input-focus-box-shadow,
			outline: 0,
			z-index: 1,
		),
	),
	$form-file
);

// Custom Control Indicator

$custom-control-indicator-size: 1rem !default;

$custom-control-indicator-bg: if(
	variable-exists(input-bg),
	$input-bg,
	$white
) !default;
$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-border-color: $gray-500 !default;
$custom-control-indicator-border-style: solid !default;
$custom-control-indicator-border-width: $border-width !default;
$custom-control-indicator-box-shadow: if(
	variable-exists(input-box-shadow),
	$input-box-shadow,
	inset 0 1px 1px rgba($black, 0.075)
) !default;
$custom-control-indicator-position-top: 0.25rem !default;

$custom-control-indicator-focus-border-color: if(
	variable-exists(input-focus-border-color),
	$input-focus-border-color,
	clay-lighten($component-active-bg, 25%)
) !default;
$custom-control-indicator-focus-box-shadow: if(
	variable-exists(input-focus-box-shadow),
	$input-focus-box-shadow,
	$component-focus-box-shadow
) !default;

$custom-control-indicator-active-bg: clay-lighten(
	$component-active-bg,
	0.35
) !default;
$custom-control-indicator-active-box-shadow: none !default;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg !default;
$custom-control-indicator-active-color: $component-active-color !default;

$custom-control-indicator-disabled-bg: if(
	variable-exists(input-disabled-bg),
	$input-disabled-bg,
	$gray-200
) !default;
$custom-control-indicator-disabled-border-color: $gray-400 !default;
$custom-control-indicator-disabled-cursor: $disabled-cursor !default;

// Custom Control Indicator Checked

$custom-control-indicator-checked-bg: $component-active-bg !default;
$custom-control-indicator-checked-color: $component-active-color !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;
$custom-control-indicator-checked-box-shadow: none !default;

/// @deprecated as of v2.2.1 use `$custom-control-indicator-checked-active-bg` instead

$custom-control-indicator-active-checked-bg: null !default;
$custom-control-indicator-checked-active-bg: $custom-control-indicator-active-checked-bg !default;

/// @deprecated as of v2.2.1 use `$custom-control-indicator-checked-active-border-color` instead

$custom-control-indicator-active-checked-border-color: null !default;
$custom-control-indicator-checked-active-border-color: $custom-control-indicator-active-checked-border-color !default;

/// @deprecated as of v2.2.1 use `$custom-control-indicator-checked-disabled-bg` instead

$custom-control-indicator-disabled-checked-bg: rgba($primary, 0.5) !default;
$custom-control-indicator-checked-disabled-bg: $custom-control-indicator-disabled-checked-bg !default;

/// @deprecated as of v2.2.1 use `$custom-control-indicator-checked-disabled-border-color` instead

$custom-control-indicator-disabled-checked-border-color: null !default;
$custom-control-indicator-checked-disabled-border-color: $custom-control-indicator-disabled-checked-border-color !default;

// .custom-control

$custom-control-cursor: null !default;
$custom-control-gutter: 0.5rem !default;
$custom-control-spacer-x: 1rem !default;

/// @deprecated after of v3.5.0 use the Sass map `$custom-control` instead

$custom-control-margin-bottom: null !default;

/// @deprecated after of v3.5.0 use the Sass map `$custom-control` instead

$custom-control-margin-top: null !default;

/// @deprecated after of v3.5.0 use the Sass map `$custom-control` instead

$custom-control-min-height: $custom-control-indicator-size +
	($custom-control-indicator-position-top * 2) !default;

// .custom-control-label

/// @deprecated as of v2.19.0 use the Sass map `$custom-control-label` instead

$custom-control-description-cursor: if(
	variable-exists(form-check-label-cursor),
	$form-check-label-cursor,
	$link-cursor
) !default;

/// @deprecated as of v2.19.0 use the Sass map `$custom-control-label` instead

$custom-control-description-font-size: 1rem !default; // 16px

/// @deprecated as of v2.19.0 use the Sass map `$custom-control-label` instead

$custom-control-description-font-weight: null !default;

/// @deprecated as of v2.19.0 use the Sass map `$custom-control-label` instead

$custom-control-description-line-height: $custom-control-min-height !default;

/// @deprecated as of v2.19.0 use the Sass map `$custom-control-label-text` instead

$custom-control-description-padding-left: 0.5rem !default;

/// @deprecated as of v2.19.0 use the Sass map `$custom-control-label-disabled` instead

$custom-control-description-disabled-cursor: $disabled-cursor !default;

/// @deprecated as of v2.19.0 use the Sass map `$custom-control-label-text-small` instead

$custom-control-description-small-font-size: null !default;

$custom-control-label-color: null !default;

$custom-control-label: () !default;
$custom-control-label: map-deep-merge(
	(
		color: $custom-control-label-color,
		cursor: $custom-control-description-cursor,
		font-size: $custom-control-description-font-size,
		font-weight: $custom-control-description-font-weight,
		line-height: $custom-control-description-line-height,
		margin-bottom: 0,
		position: static,
		vertical-align: top,
		before: (
			background-color: $custom-control-indicator-bg,
			border-color: $custom-control-indicator-border-color,
			border-style: $custom-control-indicator-border-style,
			border-width: $custom-control-indicator-border-width,
			box-shadow:
				clay-enable-shadows($custom-control-indicator-box-shadow),
			content: '',
			display: block,
			float: left,
			font-size: $custom-control-indicator-size,
			height: $custom-control-indicator-size,
			left: 0,
			position: relative,
			top: $custom-control-indicator-position-top,
			transition: clay-enable-transitions($custom-forms-transition),
			width: $custom-control-indicator-size,
		),
		after: (
			background: no-repeat 50% / #{$custom-control-indicator-bg-size},
			content: '',
			display: block,
			height: $custom-control-indicator-size,
			left: 0,
			position: absolute,
			top: $custom-control-indicator-position-top,
			width: $custom-control-indicator-size,
		),
	),
	$custom-control-label
);

$custom-control-label-disabled-color: $gray-600 !default;

$custom-control-label-disabled: () !default;
$custom-control-label-disabled: map-deep-merge(
	(
		color: $custom-control-label-disabled-color,
		cursor: $custom-control-description-disabled-cursor,
		before: (
			background-color: $custom-control-indicator-disabled-bg,
			border-color: $custom-control-indicator-disabled-border-color,
			box-shadow: none,
		),
	),
	$custom-control-label-disabled
);

// label.custom-control-label

$label-custom-control-label: () !default;
$label-custom-control-label: map-deep-merge(
	(
		font-size: map-get($custom-control-label, font-size),
	),
	$label-custom-control-label
);

// .custom-control-label-text

$custom-control-label-text: () !default;
$custom-control-label-text: map-deep-merge(
	(
		padding-left: $custom-control-description-padding-left,
	),
	$custom-control-label-text
);

// .custom-control-label-text small, .custom-control-label-text .small

$custom-control-label-text-small: () !default;
$custom-control-label-text-small: map-merge(
	(
		font-size: $custom-control-description-small-font-size,
	),
	$custom-control-label-text-small
);

$custom-control: () !default;
$custom-control: map-deep-merge(
	(
		cursor: $custom-control-cursor,
		display: block,
		margin-bottom: $custom-control-margin-bottom,
		margin-top: $custom-control-margin-top,
		min-height: $custom-control-min-height,
		position: relative,
		text-align: left,
		only-child: (
			margin-bottom: 0,
		),
		label: (
			cursor: map-get($custom-control-label, cursor),
			display: inline,
			font-size: $font-size-base,
			margin-bottom: 0,
		),
	),
	$custom-control
);

// .custom-control-input

$custom-control-input: () !default;
$custom-control-input: map-deep-merge(
	(
		cursor:
			if(
				variable-exists(form-check-input-cursor),
				$form-check-input-cursor,
				$link-cursor
			),
		height: $custom-control-indicator-size,
		left: 0,
		opacity: 0,
		position: absolute,
		top: $custom-control-indicator-position-top,
		width: $custom-control-indicator-size,
		z-index: 1,
		focus: (
			custom-control-label: (
				before: (
					border-color: $custom-control-indicator-focus-border-color,
					box-shadow: $custom-control-indicator-focus-box-shadow,
				),
			),
		),
		active: (
			custom-control-label: (
				before: (
					background-color: $custom-control-indicator-active-bg,
					border-color: $custom-control-indicator-active-border-color,
					box-shadow: $custom-control-indicator-active-box-shadow,
					color: $custom-control-indicator-active-color,
				),
			),
		),
		disabled: (
			custom-control-label: $custom-control-label-disabled,
		),
		checked: (
			custom-control-label: (
				before: (
					background-color: $custom-control-indicator-checked-bg,
					border-color: $custom-control-indicator-checked-border-color,
					box-shadow:
						clay-enable-shadows(
							$custom-control-indicator-checked-box-shadow
						),
					color: $custom-control-indicator-checked-color,
				),
			),
			active: (
				custom-control-label: (
					before: (
						background-color:
							$custom-control-indicator-checked-active-bg,
						border-color:
							$custom-control-indicator-checked-active-border-color,
					),
				),
			),
			disabled: (
				cursor: $custom-control-indicator-disabled-cursor,
				custom-control-label: (
					before: (
						background-color: $custom-control-indicator-disabled-bg,
						box-shadow: none,
					),
				),
			),
			readonly: (
				custom-control-label: (
					before: (
						background-color: $custom-control-indicator-bg,
						border-color: $custom-control-indicator-border-color,
					),
				),
			),
		),
	),
	$custom-control-input
);

// Custom Control Primary

$custom-control-primary-label-text: () !default;
$custom-control-primary-label-text: map-deep-merge(
	(
		font-weight: $font-weight-semi-bold,
	),
	$custom-control-primary-label-text
);

// Custom Checkbox

$custom-checkbox-indicator-border-radius: $border-radius !default;

$custom-checkbox-indicator-icon-checked: clay-svg-url(
	"<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>"
) !default;
$custom-checkbox-indicator-icon-checked-bg-size: null !default;

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

$custom-control-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-border-color !default;

$custom-checkbox-indicator-indeterminate-box-shadow: none !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;

$custom-checkbox-indicator-icon-indeterminate: clay-svg-url(
	"<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>"
) !default;
$custom-checkbox-indicator-icon-indeterminate-bg-size: null !default;

$custom-checkbox: () !default;
$custom-checkbox: map-deep-merge(
	(
		custom-control-input: (
			custom-control-label: (
				before: (
					border-radius:
						clay-enable-rounded(
							$custom-checkbox-indicator-border-radius
						),
				),
			),
			checked: (
				custom-control-label: (
					after: (
						background-image:
							escape-svg($custom-checkbox-indicator-icon-checked),
						background-size:
							$custom-checkbox-indicator-icon-checked-bg-size,
					),
				),
				disabled: (
					custom-control-label: (
						before: (
							background-color:
								$custom-control-indicator-checked-disabled-bg,
						),
					),
				),
				readonly: (
					custom-control-label: (
						before: (
							background-color: $white,
						),
						after: (
							background-image:
								clay-svg-url(
									"<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$gray-600}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>"
								),
						),
					),
				),
			),
			indeterminate: (
				custom-control-label: (
					before: (
						background-color:
							$custom-checkbox-indicator-indeterminate-bg,
						border-color:
							$custom-control-indicator-indeterminate-border-color,
						box-shadow:
							clay-enable-shadows(
								$custom-checkbox-indicator-indeterminate-box-shadow
							),
					),
					after: (
						background-image:
							escape-svg(
								$custom-checkbox-indicator-icon-indeterminate
							),
						background-size:
							$custom-checkbox-indicator-icon-indeterminate-bg-size,
					),
				),
				disabled: (
					custom-control-label: (
						before: (
							background-color:
								$custom-control-indicator-checked-disabled-bg,
							border-color:
								$custom-control-indicator-checked-disabled-border-color,
						),
					),
				),
				readonly: (
					custom-control-label: (
						before: (
							background-color: $custom-control-indicator-bg,
							border-color: $custom-control-indicator-border-color,
						),
						after: (
							background-image:
								clay-svg-url(
									"<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$gray-800}' d='M0 2h4'/></svg>"
								),
						),
					),
				),
			),
		),
	),
	$custom-checkbox
);

// Custom Radio

$custom-radio-indicator-border-radius: 50% !default;

$custom-radio-indicator-icon-checked: clay-svg-url(
	"<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>"
) !default;
$custom-radio-indicator-icon-checked-bg-size: null !default;

$custom-radio-indicator-disabled-border-color: null !default;

/// @deprecated as of v2.2.1 use `$custom-radio-indicator-checked-disabled-border-color` instead

$custom-radio-indicator-disabled-checked-border-color: null !default;
$custom-radio-indicator-checked-disabled-border-color: $custom-radio-indicator-disabled-checked-border-color !default;

$custom-radio: () !default;
$custom-radio: map-deep-merge(
	(
		custom-control-input: (
			custom-control-label: (
				before: (
					border-radius: $custom-radio-indicator-border-radius,
				),
			),
			disabled: (
				custom-control-label: (
					before: (
						border-color:
							$custom-radio-indicator-disabled-border-color,
					),
				),
			),
			checked: (
				custom-control-label: (
					after: (
						background-image:
							escape-svg($custom-radio-indicator-icon-checked),
						background-size:
							$custom-radio-indicator-icon-checked-bg-size,
					),
				),
				disabled: (
					custom-control-label: (
						before: (
							background-color:
								$custom-control-indicator-checked-disabled-bg,
							border-color:
								$custom-radio-indicator-checked-disabled-border-color,
						),
					),
				),
			),
		),
	),
	$custom-radio
);

$custom-control-outside: () !default;
$custom-control-outside: map-deep-merge(
	(
		label: (
			display: inline-block,
			padding-left:
				calc(
					#{$custom-control-indicator-size} + #{$custom-control-description-padding-left}
				),
		),
		custom-control-input: (
			custom-control-label: (
				before: (
					position: absolute,
				),
			),
		),
		custom-control-label-text: (
			padding-left: 0,
		),
	),
	$custom-control-outside
);

// Custom Switch

/// @deprecated as of v3.x with no replacement

$custom-switch-width: $custom-control-indicator-size * 1.75 !default;

/// @deprecated as of v3.x with no replacement

$custom-switch-indicator-border-radius: $custom-control-indicator-size * 0.5 !default;

/// @deprecated as of v3.x with no replacement

$custom-switch-indicator-size: $custom-control-indicator-size -
	($custom-control-indicator-border-width * 4) !default;

// Custom Select

/// @deprecated as of v3.x with no replacement

$custom-select-bg: if(variable-exists(input-bg), $input-bg, $white) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-bg-size: 8px 10px !default;

/// @deprecated as of v3.x with no replacement

$custom-select-border-color: if(
	variable-exists(input-border-color),
	$input-border-color,
	$gray-400
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-border-radius: $border-radius !default;

/// @deprecated as of v3.x with no replacement

$custom-select-border-width: if(
	variable-exists(input-border-width),
	$input-border-width,
	0.0625rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-box-shadow: inset 0 1px 2px rgba($black, 0.075) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-color: if(
	variable-exists(input-color),
	$input-color,
	$gray-700
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-font-family: if(
	variable-exists(input-font-family),
	$input-font-family,
	null
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-font-size: if(
	variable-exists(input-font-size),
	$input-font-size,
	$font-size-base
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-font-weight: if(
	variable-exists(input-font-weight),
	$input-font-weight,
	$font-weight-base
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-height: if(
	variable-exists(input-height),
	$input-height,
	2.375rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-indicator-padding: 1rem !default;

/// @deprecated as of v3.x with no replacement

$custom-select-line-height: if(
	variable-exists(input-line-height),
	$input-line-height,
	$line-height-base
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-padding-x: if(
	variable-exists(input-padding-x),
	$input-padding-x,
	0.75rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-padding-y: if(
	variable-exists(input-padding-y),
	$input-padding-y,
	0.375rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-indicator-color: $gray-800 !default;

/// @deprecated as of v3.x with no replacement

$custom-select-indicator: clay-svg-url(
	"<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>"
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-background: $custom-select-indicator no-repeat right
	$custom-select-padding-x center / $custom-select-bg-size !default;

// Custom Select Focus

/// @deprecated as of v3.x with no replacement

$custom-select-focus-border-color: if(
	variable-exists(input-focus-border-color),
	$input-focus-border-color,
	clay-lighten($component-active-bg, 25%)
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-focus-width: if(
	variable-exists(input-focus-width),
	$input-focus-width,
	0.2rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width
	$custom-select-focus-border-color !default;

// Custom Select Disabled

/// @deprecated as of v3.x with no replacement

$custom-select-disabled-bg: $gray-200 !default;

/// @deprecated as of v3.x with no replacement

$custom-select-disabled-color: $gray-600 !default;

// Custom Select Feedback

/// @deprecated as of v3.x with no replacement

$custom-select-feedback-icon-padding-right: calc(
	(1em * 0.75) + (2 * #{$custom-select-padding-y} * 0.75) + #{$custom-select-padding-x} +
		#{$custom-select-indicator-padding}
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-feedback-icon-position: center right
	($custom-select-padding-x + $custom-select-indicator-padding) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-feedback-icon-size: 18px 18px !default;

// Custom Select Lg

/// @deprecated as of v3.x with no replacement

$custom-select-font-size-lg: if(
	variable-exists(input-font-size-lg),
	$input-font-size-lg,
	$font-size-lg
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-height-lg: if(
	variable-exists(input-height-lg),
	$input-height-lg,
	3rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-padding-x-lg: if(
	variable-exists(input-padding-x-lg),
	$input-padding-x-lg,
	1rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-padding-y-lg: if(
	variable-exists(input-padding-y-lg),
	$input-padding-y-lg,
	0.5rem
) !default;

// Custom Select Sm

/// @deprecated as of v3.x with no replacement

$custom-select-font-size-sm: if(
	variable-exists(input-font-size-sm),
	$input-font-size-sm,
	$font-size-sm
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-height-sm: if(
	variable-exists(input-height-sm),
	$input-height-sm,
	1.9375rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-padding-x-sm: if(
	variable-exists(input-padding-x-sm),
	$input-padding-x-sm,
	0.5rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-select-padding-y-sm: if(
	variable-exists(input-padding-y-sm),
	$input-padding-y-sm,
	0.25rem
) !default;

// Custom Range

/// @deprecated as of v3.x with no replacement

$custom-range-track-bg: $gray-300 !default;

/// @deprecated as of v3.x with no replacement

$custom-range-track-border-radius: 1rem !default;

/// @deprecated as of v3.x with no replacement

$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1) !default;

/// @deprecated as of v3.x with no replacement

$custom-range-track-cursor: pointer !default;

/// @deprecated as of v3.x with no replacement

$custom-range-track-height: 0.5rem !default;

/// @deprecated as of v3.x with no replacement

$custom-range-track-width: 100% !default;

/// @deprecated as of v3.x with no replacement

$custom-range-thumb-bg: $component-active-bg !default;

/// @deprecated as of v3.x with no replacement

$custom-range-thumb-border: 0 !default;

/// @deprecated as of v3.x with no replacement

$custom-range-thumb-border-radius: 1rem !default;

/// @deprecated as of v3.x with no replacement

$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1) !default;

/// @deprecated as of v3.x with no replacement

$custom-range-thumb-width: 1rem !default;

/// @deprecated as of v3.x with no replacement

$custom-range-thumb-height: $custom-range-thumb-width !default;

/// @deprecated as of v3.x with no replacement

$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg,
	if(
		variable-exists(input-focus-box-shadow),
		$input-focus-box-shadow,
		$component-focus-box-shadow
	) !default;

/// @deprecated as of v3.x with no replacement

$custom-range-thumb-focus-box-shadow-width: if(
	variable-exists(input-focus-width),
	$input-focus-width,
	0.2rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-range-thumb-active-bg: clay-lighten($component-active-bg, 35%) !default;

/// @deprecated as of v3.x with no replacement

$custom-range-thumb-disabled-bg: $gray-500 !default;

// Custom File

/// @deprecated as of v3.x with no replacement

$custom-file-bg: if(variable-exists(input-bg), $input-bg, $white) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-border-color: if(
	variable-exists(input-border-color),
	$input-border-color,
	$gray-400
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-border-width: if(
	variable-exists(input-border-width),
	$input-border-width,
	0.0625rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-border-radius: if(
	variable-exists(input-border-radius),
	$input-border-radius,
	$border-radius
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-box-shadow: if(
	variable-exists(input-box-shadow),
	$input-box-shadow,
	inset 0 1px 1px rgba($black, 0.075)
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-color: if(
	variable-exists(input-color),
	$input-color,
	$gray-700
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-font-family: if(
	variable-exists(input-font-family),
	$input-font-family,
	null
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-font-weight: if(
	variable-exists(input-font-weight),
	$input-font-weight,
	$font-weight-base
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-height-inner: 36px !default;

/// @deprecated as of v3.x with no replacement

$custom-file-height: if(
	variable-exists(input-height),
	$input-height,
	2.375rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-line-height: if(
	variable-exists(input-line-height),
	$input-line-height,
	$line-height-base
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-padding-x: if(
	variable-exists(input-padding-x),
	$input-padding-x,
	0.75rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-padding-y: if(
	variable-exists(input-padding-y),
	$input-padding-y,
	0.375rem
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-focus-border-color: if(
	variable-exists(input-focus-border-color),
	$input-focus-border-color,
	clay-lighten($component-active-bg, 25%)
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-focus-box-shadow: if(
	variable-exists(input-focus-box-shadow),
	$input-focus-box-shadow,
	$component-focus-box-shadow
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-disabled-bg: if(
	variable-exists(input-disabled-bg),
	$input-disabled-bg,
	$gray-200
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-button-bg: if(
	variable-exists(input-group-addon-bg),
	$input-group-addon-bg,
	$gray-200
) !default;

/// @deprecated as of v3.x with no replacement

$custom-file-button-color: $custom-file-color !default;

// .custom-file-label::after

/// @deprecated as of v3.x with no replacement. See https://issues.liferay.com/browse/LPS-147457 and https://github.com/sass/sass/issues/1395

$custom-file-label-after-content: unquote("'\\FEFF Browse'") !default;

$custom-file-text: () !default;

/// @deprecated as of v3.x with no replacement. See https://issues.liferay.com/browse/LPS-147457 and https://github.com/sass/sass/issues/1395

$custom-file-text: map-deep-merge(
	(
		en: unquote("'\\FEFF Browse'"),
	),
	$custom-file-text
);

/// @deprecated as of v3.x with no replacement. See https://issues.liferay.com/browse/LPS-147457.

$custom-file-text-data-browse: unquote("'\\FEFF' attr(data-browse)") !default;
