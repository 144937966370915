$list-group-bg: $white !default;
$list-group-border-color: rgba($black, 0.125) !default;
$list-group-border-width: $border-width !default;

$list-group-border-radius: $border-radius !default;

$list-group-box-shadow: null !default;
$list-group-color: null !default;
$list-group-font-size: null !default;
$list-group-margin-bottom: 1.5rem !default;
$list-group-margin-top: null !default;

// List Group Item Action

$list-group-action-color: $gray-700 !default;

$list-group-hover-bg: $gray-100 !default;

$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-bg: $gray-200 !default;
$list-group-action-active-color: $body-color !default;

// List Group Item

$list-group-item-padding-x: 1.25rem !default;
$list-group-item-padding-y: 0.75rem !default;

// Use values flex-start, flex-end, or center

$list-group-item-flex-align-items: flex-start !default;

$list-group-item-flex-checkbox-offset-top: 0.1875rem !default;
$list-group-item-flex-offset-top: 0.0625rem !default;
$list-group-item-flex-list-group-title-offset-top: -0.25rem !default;

$list-group-item-flex: () !default;
$list-group-item-flex: map-deep-merge(
	(
		display: flex,
		margin-bottom: math-sign($list-group-border-width),
		padding-left: calc(#{$list-group-item-padding-x} * 0.5),
		padding-right: calc(#{$list-group-item-padding-x} * 0.5),
		autofit-col: (
			justify-content: $list-group-item-flex-align-items,
			padding-left: calc(#{$list-group-item-padding-x} * 0.5),
			padding-right: calc(#{$list-group-item-padding-x} * 0.5),
		),
	),
	$list-group-item-flex
);

$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;
$list-group-active-color: $component-active-color !default;

$list-group-disabled-bg: $list-group-bg !default;
$list-group-disabled-color: $gray-600 !default;

// List Group Header

$list-group-header-bg: $list-group-bg !default;
$list-group-header-padding-x: $list-group-item-padding-x !default;
$list-group-header-padding-y: $list-group-item-padding-y !default;

// .list-group-header-title

$list-group-header-title: () !default;
$list-group-header-title: map-deep-merge(
	(
		font-size: 1rem,
		font-weight: $font-weight-semi-bold,
		line-height: $headings-line-height,
		margin-bottom: 0,
	),
	$list-group-header-title
);

// .list-group-title

$list-group-title-link: () !default;
$list-group-title-link: map-deep-merge(
	(
		color: $gray-900,
		max-width: 100%,
		hover: (
			color: clay-darken($gray-900, 15%),
		),
	),
	$list-group-title-link
);

$list-group-title: () !default;
$list-group-title: map-deep-merge(
	(
		// 18px
		font-size: 1.125rem,
		font-weight: $font-weight-semi-bold,
		line-height: $line-height-base,
		margin-bottom: 0,
		margin-top: $list-group-item-flex-list-group-title-offset-top,
		href: $list-group-title-link
	),
	$list-group-title
);

$list-group-title-active-color: $list-group-active-color !default;

// .list-group-subtitle

$list-group-subtitle-link: () !default;
$list-group-subtitle-link: map-deep-merge(
	(
		color: $gray-600,
		hover: (
			color: clay-darken($gray-600, 15%),
		),
	),
	$list-group-subtitle-link
);

$list-group-subtitle: () !default;
$list-group-subtitle: map-deep-merge(
	(
		color: $gray-600,
		margin-bottom: 0,
		href: $list-group-subtitle-link,
	),
	$list-group-subtitle
);

// .list-group-text

$list-group-text-link: () !default;
$list-group-text-link: map-deep-merge(
	(
		color: $gray-900,
		hover: (
			color: clay-darken($gray-900, 15%),
		),
	),
	$list-group-text-link
);

$list-group-text: () !default;
$list-group-text: map-deep-merge(
	(
		color: $gray-900,
		margin-bottom: 0,
		href: $list-group-text-link,
	),
	$list-group-text
);

$list-group-text-active-color: $list-group-active-color !default;

// .list-group-subtext

$list-group-subtext-link: () !default;
$list-group-subtext-link: map-deep-merge(
	(
		color: $gray-600,
		hover: (
			color: clay-darken($gray-600, 15%),
		),
	),
	$list-group-subtext-link
);

$list-group-subtext: () !default;
$list-group-subtext: map-deep-merge(
	(
		color: $gray-600,
		margin-bottom: 0,
		href: $list-group-subtext-link,
	),
	$list-group-subtext
);

$list-group-subtext-active-color: $list-group-active-color !default;

// List Group Detail

$list-group-detail-margin-bottom: null !default;
$list-group-detail-margin-top: null !default;

$list-group-link-color: null !default;
$list-group-link-hover-color: null !default;
$list-group-link-active-color: $white !default;

$list-group: () !default;
$list-group: map-deep-merge(
	(
		border-radius: clay-enable-rounded($list-group-border-radius),
		box-shadow: clay-enable-shadows($list-group-box-shadow),
		color: $list-group-color,
		display: flex,
		flex-direction: column,
		font-size: $list-group-font-size,
		margin-bottom: $list-group-margin-bottom,
		margin-top: $list-group-margin-top,
		padding-left: 0,
		list-group: (
			box-shadow: if($list-group-box-shadow != null, none, null),
		),
	),
	$list-group
);

// List Group Item Variants

$list-group-item-theme-colors: () !default;
$list-group-item-theme-colors: map-deep-merge(
	(
		'primary': (
			background-color: theme-color-level(primary, -9),
			color: theme-color-level(primary, 6),
			hover: (
				background-color:
					clay-darken(theme-color-level(primary, -9), 5%),
				color: theme-color-level(primary, 6),
			),
			active: (
				background-color: theme-color-level(primary, 6),
				border-color: theme-color-level(primary, 6),
				color: $white,
			),
		),
		'secondary': (
			background-color: theme-color-level(secondary, -9),
			color: theme-color-level(secondary, 6),
			hover: (
				background-color:
					clay-darken(theme-color-level(secondary, -9), 5%),
				color: theme-color-level(secondary, 6),
			),
			active: (
				background-color: theme-color-level(secondary, 6),
				border-color: theme-color-level(secondary, 6),
				color: $white,
			),
		),
		'success': (
			background-color: theme-color-level(success, -9),
			color: theme-color-level(success, 6),
			hover: (
				background-color:
					clay-darken(theme-color-level(success, -9), 5%),
				color: theme-color-level(success, 6),
			),
			active: (
				background-color: theme-color-level(success, 6),
				border-color: theme-color-level(success, 6),
				color: $white,
			),
		),
		'info': (
			background-color: theme-color-level(info, -9),
			color: theme-color-level(info, 6),
			hover: (
				background-color: clay-darken(theme-color-level(info, -9), 5%),
				color: theme-color-level(info, 6),
			),
			active: (
				background-color: theme-color-level(info, 6),
				border-color: theme-color-level(info, 6),
				color: $white,
			),
		),
		'warning': (
			background-color: theme-color-level(warning, -9),
			color: theme-color-level(warning, 6),
			hover: (
				background-color:
					clay-darken(theme-color-level(warning, -9), 5%),
				color: theme-color-level(warning, 6),
			),
			active: (
				background-color: theme-color-level(warning, 6),
				border-color: theme-color-level(warning, 6),
				color: $white,
			),
		),
		'danger': (
			background-color: theme-color-level(danger, -9),
			color: theme-color-level(danger, 6),
			hover: (
				background-color: clay-darken(theme-color-level(danger, -9), 5%),
				color: theme-color-level(danger, 6),
			),
			active: (
				background-color: theme-color-level(danger, 6),
				border-color: theme-color-level(danger, 6),
				color: $white,
			),
		),
		'light': (
			background-color: theme-color-level(light, -9),
			color: theme-color-level(light, 6),
			hover: (
				background-color: clay-darken(theme-color-level(light, -9), 5%),
				color: theme-color-level(light, 6),
			),
			active: (
				background-color: theme-color-level(light, 6),
				border-color: theme-color-level(light, 6),
				color: $white,
			),
		),
		'dark': (
			background-color: theme-color-level(dark, -9),
			color: theme-color-level(dark, 6),
			hover: (
				background-color: clay-darken(theme-color-level(dark, -9), 5%),
				color: theme-color-level(dark, 6),
			),
			active: (
				background-color: theme-color-level(dark, 6),
				border-color: theme-color-level(dark, 6),
				color: $white,
			),
		),
	),
	$list-group-item-theme-colors
);

// List Group Notification

$list-group-notification-item-border-bottom-color: $list-group-border-color !default;
$list-group-notification-item-border-left-color: $list-group-border-color !default;
$list-group-notification-item-border-right-color: $list-group-border-color !default;
$list-group-notification-item-border-top-color: $list-group-border-color !default;

$list-group-notification-item-active-border-bottom-color: $list-group-active-border-color !default;
$list-group-notification-item-active-border-left-color: $list-group-active-border-color !default;
$list-group-notification-item-active-border-right-color: $list-group-active-border-color !default;
$list-group-notification-item-active-border-top-color: $list-group-active-border-color !default;

$list-group-notification-item-border-bottom-width: 0.0625rem !default;
$list-group-notification-item-border-left-width: 0.0625rem !default;
$list-group-notification-item-border-right-width: 0.0625rem !default;
$list-group-notification-item-border-top-width: 0.0625rem !default;

$list-group-notification-item: () !default;

$list-group-notification-item-primary: () !default;
$list-group-notification-item-primary: map-deep-merge(
	(
		border-left-color: theme-color-level(primary, -9),
		border-left-width: 0.5rem,
		border-bottom-left-radius: 0px,
		border-top-left-radius: 0px,
		active-border-left-color: theme-color-level(primary, -9),
	),
	$list-group-notification-item-primary
);

// List Group Sm

$list-group-sm-item: () !default;
$list-group-sm-item: map-merge(
	(
		padding-bottom: 0.40625rem,
		padding-top: 0.40625rem,
	),
	$list-group-sm-item
);

$list-group-sm-quick-action-menu: () !default;
$list-group-sm-quick-action-menu: map-merge(
	(
		margin-bottom: -0.40625rem,
		margin-top: -0.40625rem,
		padding-bottom: 0.40625rem,
		padding-top: 0.40625rem,
	),
	$list-group-sm-quick-action-menu
);
