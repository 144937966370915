////
/// @group slideout
////

/// A mixin to create `.c-slideout` variants.
/// @param {Map} $map - A map of `key: value` pairs. The keys and value types are listed below:
/// @example
/// enabled: {Bool},  // Set to false to prevent mixin styles from being output. Default: true
/// c-slideout-shown: {Map | Null}, // Pass parameters to `clay-css` mixin
/// c-slideout-tbar-shown: {Map | Null}, // Pass parameters to `clay-css` mixin
/// c-slideout-tbar-shown-sidebar: {Map | Null}, // Pass parameters to `clay-css` mixin
/// sidebar: {Map | Null}, // Pass parameters to `clay-css` mixin
/// sidebar-c-slideout-show: {Map | Null}, // Pass parameters to `clay-css` mixin
/// sidebar-c-slideout-transition: {Map | Null}, // Pass parameters to `clay-css` mixin
/// tbar-stacked: {Map | Null}, // Pass parameters to `clay-css` mixin
/// tbar-stacked-c-slideout-show: {Map | Null}, // Pass parameters to `clay-css` mixin
/// tbar-stacked-c-slideout-transition: {Map | Null}, // Pass parameters to `clay-css` mixin

@mixin clay-slideout-variant($map) {
	@if (type-of($map) == 'map') {
		$enabled: setter(map-get($map, enabled), true);

		@if ($enabled) {
			@include clay-css($map);

			&.c-slideout-shown {
				@include clay-css(map-get($map, c-slideout-shown));
			}

			&.c-slideout-tbar-shown {
				@include clay-css(map-get($map, c-slideout-tbar-shown));
			}

			&.c-slideout-tbar-shown .sidebar {
				@include clay-css(map-get($map, c-slideout-tbar-shown-sidebar));
			}

			.sidebar {
				@include clay-css(map-get($map, sidebar));
			}

			.sidebar.c-slideout-show {
				@include clay-css(map-get($map, sidebar-c-slideout-show));
			}

			.sidebar.c-slideout-transition {
				$_sidebar-c-slideout-transition: setter(
					map-get($map, sidebar-c-slideout-transition),
					()
				);

				@include clay-css($_sidebar-c-slideout-transition);

				.c-horizontal-resizer {
					@include clay-css(
						map-get(
							$_sidebar-c-slideout-transition,
							c-horizontal-resizer
						)
					);
				}
			}

			.tbar-stacked {
				@include clay-css(map-get($map, tbar-stacked));
			}

			.tbar-stacked.c-slideout-show {
				@include clay-css(map-get($map, tbar-stacked-c-slideout-show));
			}

			.tbar-stacked.c-slideout-transition {
				@include clay-css(
					map-get($map, tbar-stacked-c-slideout-transition)
				);
			}

			.c-horizontal-resizer {
				$_c-horizontal-resizer: setter(
					map-get($map, c-horizontal-resizer),
					()
				);

				@include clay-css($_c-horizontal-resizer);

				&:hover {
					@include clay-css(map-get($_c-horizontal-resizer, hover));
				}

				@at-root {
					#{$focus-visible-selector},
					#{if($c-prefers-focus-selector,$c-prefers-focus-selector,clay-insert-before('.cadmin', '.c-prefers-focus ', '&:focus'))} {
						@include clay-css(
							map-get($_c-horizontal-resizer, focus)
						);
					}
				}
			}
		}
	}
}
