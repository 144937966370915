$toggle-switch-cursor: $link-cursor !default;
$toggle-switch-font-weight: if(
	variable-exists(input-label-font-weight),
	$input-label-font-weight,
	null
) !default;
$toggle-switch-transition: background-color 100ms ease-in,
	border-color 100ms ease-in, box-shadow 150ms ease-in-out,
	color 100ms ease-in, left 100ms ease-in, opacity 100ms ease-in,
	right 100ms ease-in !default;

// must all be same units--start
$toggle-switch-bar-padding: 0 !default; // space between button and bar, can be negative value
$toggle-switch-button-width: 25px !default;
$toggle-switch-bar-height: ($toggle-switch-bar-padding * 2) +
	$toggle-switch-button-width !default;
$toggle-switch-bar-width: 50px !default; // width of switch bar

$toggle-switch-bar-padding-mobile: $toggle-switch-bar-padding !default;
$toggle-switch-button-width-mobile: $toggle-switch-button-width !default;
$toggle-switch-bar-height-mobile: $toggle-switch-bar-height !default;
$toggle-switch-bar-width-mobile: $toggle-switch-bar-width !default;
// must all be same units--end

$toggle-switch-bar-bg: $gray-200 !default;
$toggle-switch-bar-border-color: $gray-400 !default;
$toggle-switch-bar-border-radius: 3px !default;
$toggle-switch-bar-border-width: 1px !default;
$toggle-switch-bar-focus-box-shadow: $component-focus-box-shadow !default;
$toggle-switch-bar-font-size: 0.75rem !default; // 12px
$toggle-switch-bar-font-size-mobile: $toggle-switch-bar-font-size !default;
$toggle-switch-bar-icon-color: $gray-700 !default;

$toggle-switch-button-bg: $white !default;
$toggle-switch-button-border-color: $gray-400 !default;
$toggle-switch-button-border-radius: 3px 0 0 3px !default;
$toggle-switch-button-border-width: 1px !default;
$toggle-switch-button-font-size: $toggle-switch-bar-font-size !default;
$toggle-switch-button-font-size-mobile: $toggle-switch-button-font-size !default; // null
$toggle-switch-button-icon-color: $gray-700 !default;

// See https://issues.liferay.com/browse/LPS-147457.

$toggle-switch-handle-after-content: unquote(
	"'\\FEFF' attr(data-label-off)"
) !default;

// Toggle Switch On

$toggle-switch-bar-on-bg: $component-active-bg !default;
$toggle-switch-bar-on-border-color: $toggle-switch-bar-on-bg !default;
$toggle-switch-bar-on-icon-color: $component-active-color !default;

$toggle-switch-button-on-bg: $white !default;
$toggle-switch-button-on-border-color: $toggle-switch-bar-on-bg !default;
$toggle-switch-button-on-border-radius: 0 3px 3px 0 !default;
$toggle-switch-button-on-icon-color: $toggle-switch-bar-on-bg !default;

// See https://issues.liferay.com/browse/LPS-147457.

$toggle-switch-handle-on-after-content: unquote(
	"'\\FEFF' attr(data-label-on)"
) !default;

// Toggle Switch Disabled

$toggle-switch-disabled-cursor: $disabled-cursor !default;
$toggle-switch-disabled-opacity: 0.4 !default;

// Toggle Switch Label

$toggle-switch-label-disabled-color: if(
	variable-exists(input-label-disabled-color),
	$input-label-disabled-color,
	$gray-600
) !default;

// Toggle Switch Text

$toggle-switch-text-font-size: 0.75rem !default;

$toggle-switch-text-disabled-color: if(
	variable-exists(input-label-disabled-color),
	$input-label-disabled-color,
	$gray-600
) !default;

// .toggle-switch

$toggle-switch: () !default;
$toggle-switch: map-deep-merge(
	(
		breakpoint-down: sm,
		cursor: $toggle-switch-cursor,
		display: inline-block,
		font-weight: $toggle-switch-font-weight,
		max-width: 100%,
		position: relative,
		disabled: (
			cursor: $toggle-switch-disabled-cursor,
			toggle-switch-label: (
				color: $toggle-switch-label-disabled-color,
				cursor: $toggle-switch-disabled-cursor,
			),
			toggle-switch-text: (
				color: $toggle-switch-text-disabled-color,
			),
		),
	),
	$toggle-switch
);

// .toggle-switch-check-bar

$toggle-switch-check-bar: () !default;
$toggle-switch-check-bar: map-merge(
	(
		display: inline-flex,
		position: relative,
	),
	$toggle-switch-check-bar
);

// .toggle-switch-bar

$toggle-switch-bar: () !default;
$toggle-switch-bar: map-deep-merge(
	(
		toggle-switch-handle: (
			display: block,
			min-width: $toggle-switch-bar-width,
			text-transform: uppercase,
		),
		toggle-switch-icon: (
			font-size: $toggle-switch-bar-font-size,
			lexicon-icon: (
				margin-top: -0.2em,
			),
		),
		button-icon: (
			font-size: $toggle-switch-button-font-size,
		),
	),
	$toggle-switch-bar
);

// .toggle-switch-check

$toggle-switch-check: () !default;
$toggle-switch-check: map-deep-merge(
	(
		breakpoint-down: sm,
		bottom: 0,
		font-size: 62.5%,
		height: $toggle-switch-bar-height,
		opacity: 0,
		position: absolute,
		width: $toggle-switch-bar-width,
		z-index: 2,
		toggle-switch-bar: (
			display: inline-flex,
			font-size: $toggle-switch-bar-font-size,
			height: $toggle-switch-bar-height,
			line-height: $toggle-switch-bar-height,
			position: relative,
			text-indent: 0,
			user-select: none,
			before: (
				background-color: $toggle-switch-bar-bg,
				border-color: $toggle-switch-bar-border-color,
				border-radius:
					clay-enable-rounded($toggle-switch-bar-border-radius),
				border-style: solid,
				border-width: $toggle-switch-bar-border-width,
				bottom: 0,
				content: ' ',
				display: block,
				left: 0,
				position: absolute,
				top: 0,
				transition: $toggle-switch-transition,
				width: $toggle-switch-bar-width,
			),
			after: (
				background-color: $toggle-switch-button-bg,
				border-color: $toggle-switch-button-border-color,
				border-radius:
					clay-enable-rounded($toggle-switch-button-border-radius),
				border-style: solid,
				border-width: $toggle-switch-button-border-width,
				bottom: $toggle-switch-bar-padding,
				content: '',
				display: block,
				height: $toggle-switch-button-width,
				left: $toggle-switch-bar-padding,
				position: absolute,
				top: $toggle-switch-bar-padding,
				transition: $toggle-switch-transition,
				width: $toggle-switch-button-width,
			),
			toggle-switch-handle: (
				before: (
					transition:
						clay-enable-transitions($toggle-switch-transition),
				),
				after: (
					content: $toggle-switch-handle-after-content,
					margin-left:
						clay-data-label-text-position(
							$toggle-switch-bar-width,
							$toggle-switch-bar-padding
						),
					transition: $toggle-switch-transition,
					white-space: nowrap,
				),
			),
			toggle-switch-icon: (
				color: $toggle-switch-bar-icon-color,
				left: $toggle-switch-bar-padding,
				line-height: $toggle-switch-button-width,
				position: absolute,
				text-align: center,
				text-indent: 0,
				top: $toggle-switch-bar-padding,
				transition: $toggle-switch-transition,
				width: $toggle-switch-button-width,
				z-index: 1,
			),
			toggle-switch-icon-on: (
				left: $toggle-switch-bar-padding,
				opacity: 0,
			),
			toggle-switch-icon-off: (
				left: $toggle-switch-bar-width - $toggle-switch-bar-padding -
					$toggle-switch-button-width,
			),
			button-icon: (
				color: $toggle-switch-button-icon-color,
			),
			button-icon-on: (
				opacity: 0,
			),
		),
		focus: (
			toggle-switch-bar: (
				before: (
					box-shadow: $toggle-switch-bar-focus-box-shadow,
				),
			),
		),
		disabled: (
			toggle-switch-bar: (
				cursor: $toggle-switch-disabled-cursor,
				opacity: $toggle-switch-disabled-opacity,
			),
		),
		checked: (
			toggle-switch-bar: (
				before: (
					background-color: $toggle-switch-bar-on-bg,
					border-color: $toggle-switch-bar-on-border-color,
					border-radius:
						clay-enable-rounded($toggle-switch-bar-border-radius),
					border-style: solid,
					border-width: $toggle-switch-bar-border-width,
				),
				after: (
					background-color: $toggle-switch-button-on-bg,
					border-color: $toggle-switch-button-on-border-color,
					border-radius:
						clay-enable-rounded(
							$toggle-switch-button-on-border-radius
						),
					border-style: solid,
					border-width: $toggle-switch-button-border-width,
					left: $toggle-switch-bar-width - $toggle-switch-bar-padding -
						$toggle-switch-button-width,
				),
				toggle-switch-handle: (
					after: (
						content: $toggle-switch-handle-on-after-content,
					),
				),
				toggle-switch-icon: (
					color: $toggle-switch-bar-on-icon-color,
				),
				toggle-switch-icon-on: (
					opacity: 1,
				),
				toggle-switch-icon-off: (
					opacity: 0,
				),
				button-icon: (
					color: $toggle-switch-button-on-icon-color,
					left: $toggle-switch-bar-width - $toggle-switch-bar-padding -
						$toggle-switch-button-width,
				),
				button-icon-on: (
					opacity: 1,
				),
				button-icon-off: (
					opacity: 0,
				),
			),
		),
		mobile: (
			height: $toggle-switch-bar-height-mobile,
			width: $toggle-switch-bar-width-mobile,
			toggle-switch-bar: (
				height: $toggle-switch-bar-height-mobile,
				line-height: $toggle-switch-bar-height-mobile,
				text-indent: 0,
				before: (
					width: $toggle-switch-bar-width-mobile,
				),
				after: (
					bottom: $toggle-switch-bar-padding-mobile,
					height: $toggle-switch-button-width-mobile,
					left: $toggle-switch-bar-padding-mobile,
					top: $toggle-switch-bar-padding-mobile,
					width: $toggle-switch-button-width-mobile,
				),
				toggle-switch-handle: (
					min-width: $toggle-switch-bar-width-mobile,
					after: (
						margin-left:
							clay-data-label-text-position(
								$toggle-switch-bar-width-mobile,
								$toggle-switch-bar-padding-mobile
							),
					),
				),
				toggle-switch-icon: (
					font-size: $toggle-switch-bar-font-size-mobile,
					left: $toggle-switch-bar-padding-mobile,
					line-height: $toggle-switch-button-width-mobile,
					top: $toggle-switch-bar-padding-mobile,
					width: $toggle-switch-button-width-mobile,
				),
				toggle-switch-icon-on: (
					left: $toggle-switch-bar-padding-mobile,
				),
				toggle-switch-icon-off: (
					left: $toggle-switch-bar-width-mobile -
						$toggle-switch-bar-padding-mobile -
						$toggle-switch-button-width-mobile,
				),
				button-icon: (
					font-size: $toggle-switch-button-font-size-mobile,
				),
			),
			checked: (
				toggle-switch-bar: (
					after: (
						left: $toggle-switch-bar-width-mobile -
							$toggle-switch-bar-padding-mobile -
							$toggle-switch-button-width-mobile,
					),
					toggle-switch-handle: (
						after: (
							margin-left:
								clay-data-label-text-position(
									$toggle-switch-bar-width-mobile,
									$toggle-switch-bar-padding-mobile
								),
						),
					),
					button-icon: (
						left: $toggle-switch-bar-width-mobile -
							$toggle-switch-bar-padding-mobile -
							$toggle-switch-button-width-mobile,
					),
				),
			),
		),
	),
	$toggle-switch-check
);

// .toggle-switch-label

$toggle-switch-label: () !default;
$toggle-switch-label: map-merge(
	(
		display: block,
		margin-bottom: 2px,
	),
	$toggle-switch-label
);

// .toggle-switch-text

$toggle-switch-text: () !default;
$toggle-switch-text: map-merge(
	(
		display: block,
		font-size: $toggle-switch-text-font-size,
	),
	$toggle-switch-text
);

// .toggle-switch-text-left

$toggle-switch-text-left: () !default;
$toggle-switch-text-left: map-merge(
	(
		breakpoint-down: sm,
		display: inline-flex,
		line-height: $toggle-switch-bar-height,
		margin-right: 8px,
		mobile: (
			line-height: $toggle-switch-bar-height-mobile,
		),
	),
	$toggle-switch-text-left
);

// .toggle-switch-text-right

$toggle-switch-text-right: () !default;
$toggle-switch-text-right: map-deep-merge(
	(
		breakpoint-down: sm,
		display: inline-flex,
		line-height: $toggle-switch-bar-height,
		margin-left: 8px,
		mobile: (
			line-height: $toggle-switch-bar-height-mobile,
		),
	),
	$toggle-switch-text-right
);

// .simple-toggle-switch

$simple-toggle-switch-label-line-height: 1 !default;
$simple-toggle-switch-label-spacer-x: 0.5rem !default;

$simple-toggle-switch: () !default;
$simple-toggle-switch: map-deep-merge(
	(
		breakpoint-down: sm,
		align-items: center,
		display: inline-flex,
		toggle-switch-label: (
			line-height: $simple-toggle-switch-label-line-height,
			margin-bottom: 0,
			max-width:
				calc(
					100% - #{clay-data-label-text-position(
							$toggle-switch-bar-width,
							$toggle-switch-bar-padding
						)}
				),
		),
		mobile: (
			toggle-switch-label: (
				max-width:
					calc(
						100% - #{clay-data-label-text-position(
								$toggle-switch-bar-width-mobile,
								$toggle-switch-bar-padding-mobile
							)}
					),
			),
		),
	),
	$simple-toggle-switch
);

// .simple-toggle-switch-reverse

$simple-toggle-switch-reverse: () !default;
$simple-toggle-switch-reverse: map-deep-merge(
	(
		breakpoint-down: sm,
		toggle-switch-check-bar: (
			order: 5,
		),
		toggle-switch-check: (
			toggle-switch-bar: (
				order: 5,
			),
		),
		toggle-switch-label: (
			margin-right: $simple-toggle-switch-label-spacer-x,
		),
	),
	$simple-toggle-switch-reverse
);

// .toggle-switch-sm

$toggle-switch-sizes: () !default;
$toggle-switch-sizes: map-deep-merge(
	(
		toggle-switch-sm: (
			simple-toggle-switch: (
				toggle-switch-label: (
					max-width: calc(100% - 34px),
				),
			),
			toggle-switch-check: (
				height: 16px,
				width: 30px,
				toggle-switch-bar: (
					height: 16px,
					line-height: 16px,
					before: (
						width: 30px,
					),
					after: (
						height: 16px,
						width: 16px,
					),
					toggle-switch-handle: (
						min-width: 30px,
						after: (
							margin-left: 34px,
						),
					),
					toggle-switch-icon: (
						font-size: 0.6875rem,
						height: 16px,
						left: 0,
						line-height: 16px,
						width: 16px,
					),
					toggle-switch-icon-off: (
						left: 14px,
					),
				),
				checked: (
					toggle-switch-bar: (
						after: (
							left: 14px,
						),
						button-icon: (
							left: 14px,
						),
					),
				),
			),
			toggle-switch-label: (
				font-size: 0.75rem,
				max-width: calc(100% - 38px),
			),
		),
	),
	$toggle-switch-sizes
);
