////
/// @group nav
////

/// A mixin to indent each nested navigation using `padding-left`.
/// @param {Number} $indent[15px] - The amount to indent in pixels
/// @param {Number} $nest-level[15] - The number of times to iterate
/// @param {String} $nav-class[".nav"] - The class name of nav the element
/// @param {Number} $i[1] - The starting loop index
/// @todo
/// - Add @example
/// - Add @link to documentation

@mixin clay-nav-nested(
	$indent: 15px,
	$nest-level: 15,
	$nav-class: '.nav',
	$i: 1
) {
	$_nav-class-next: '#{$nav-class}';

	@for $i from (1) through $nest-level {
		#{$_nav-class-next} > li {
			> a,
			> .btn {
				padding-left: calc(#{$indent} * #{$i + 1});

				.c-inner {
					margin-left: calc(#{$indent} * -#{$i + 1});
				}
			}

			> .nav-equal-height-heading {
				padding-left: calc(#{$indent} * #{$i});
			}
		}

		$_nav-class-next: '#{$nav-class} #{$_nav-class-next}';
	}
}
