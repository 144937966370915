.lexicon-icon {
	@include clay-css($lexicon-icon);
}

.lexicon-icon-sm {
	@include clay-css($lexicon-icon-sm);
}

.lexicon-icon-lg {
	@include clay-css($lexicon-icon-lg);
}

.lexicon-icon-xl {
	@include clay-css($lexicon-icon-xl);
}

.order-arrow-down-active {
	.order-arrow-arrow-down {
		fill: $order-arrow-down-active-color;
	}
}

.order-arrow-up-active {
	.order-arrow-arrow-up {
		fill: $order-arrow-up-active-color;
	}
}

// Collapse Icon

a.collapse-icon,
button.collapse-icon,
.collapse-icon[tabindex] {
	padding-left: $collapse-icon-padding-left;
	padding-right: $collapse-icon-padding-right;

	@if ($enable-c-inner) {
		.c-inner {
			margin-left: math-sign($collapse-icon-padding-left);
			margin-right: math-sign($collapse-icon-padding-right);
		}
	}
}

.collapse-icon-closed,
.collapse-icon-open {
	.lexicon-icon {
		display: block;
	}
}

.collapse-icon {
	.collapse-icon-closed,
	.collapse-icon-open {
		bottom: $collapse-icon-position-bottom;
		height: 1em;
		left: $collapse-icon-position-left;
		position: absolute;
		right: $collapse-icon-position-right;
		top: $collapse-icon-position-top;
		width: 1em;

		.lexicon-icon {
			margin-top: 0;
		}
	}

	.collapse-icon-closed {
		display: none;
	}

	.collapse-icon-open {
		display: inline-block;
	}
}

.collapsed {
	.collapse-icon-closed {
		display: inline-block;
	}

	.collapse-icon-open {
		display: none;
	}
}

.collapse-icon.collapse-icon-middle {
	.collapse-icon-closed,
	.collapse-icon-open {
		margin-top: 0;
		top: 50%;

		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}
