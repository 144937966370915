$card-bg: $white !default;
$card-border-color: rgba($black, 0.125) !default;
$card-border-style: solid !default;
$card-border-width: $border-width !default;

$card-border-radius: $border-radius !default;
$card-inner-border-radius: calc(
	#{$card-border-radius} - #{$card-border-width}
) !default;

$card-box-shadow: null !default;
$card-color: null !default;
$card-height: null !default;
$card-margin-bottom: 1.5rem !default;

$card-spacer-x: 1.25rem !default;
$card-spacer-y: 0.75rem !default;

$card-cap-bg: rgba($black, 0.03) !default;
$card-cap-color: null !default;

// .card-body

$card-body-padding-bottom: $card-spacer-x !default;
$card-body-padding-left: $card-spacer-x !default;
$card-body-padding-right: $card-spacer-x !default;
$card-body-padding-top: $card-spacer-x !default;

// Enable `flex-grow: 1` for decks and groups so that card blocks take up
// as much space as possible, ensuring footers are aligned to the bottom.

// `min-height` workaround for the image size bug in IE
// See: https://github.com/twbs/bootstrap/pull/28855

$card-body: () !default;
$card-body: map-deep-merge(
	(
		color: $card-color,
		flex: 1 1 auto,
		min-height: 1px,
		padding-bottom: $card-body-padding-bottom,
		padding-left: $card-body-padding-left,
		padding-right: $card-body-padding-right,
		padding-top: $card-body-padding-top,
	),
	$card-body
);

// .card-section-header

$card-section-header-color: $gray-600 !default;
$card-section-header-font-size: 0.75rem !default;
$card-section-header-font-weight: $font-weight-semi-bold !default;
$card-section-header-line-height: 2.5 !default;
$card-section-header-margin-bottom: 1.5rem !default;
$card-section-header-padding: 0 ($grid-gutter-width * 0.5) !default;
$card-section-header-text-transform: uppercase !default;

$card-section-header: () !default;
$card-section-header: map-deep-merge(
	(
		color: $card-section-header-color,
		font-size: $card-section-header-font-size,
		font-weight: $card-section-header-font-weight,
		line-height: $card-section-header-line-height,
		margin-bottom: $card-section-header-margin-bottom,
		padding: $card-section-header-padding,
		text-transform: $card-section-header-text-transform,
	),
	$card-section-header
);

/// @deprecated as of v3.x with no replacement

$card-img-overlay-padding: 1.25rem !default;

/// @deprecated as of v3.x with no replacement

$card-group-margin: $grid-gutter-width * 0.5 !default;

/// @deprecated as of v3.x with no replacement

$card-deck-margin: $card-group-margin !default;

/// @deprecated as of v3.x with no replacement

$card-columns-count: 3 !default;

/// @deprecated as of v3.x with no replacement

$card-columns-gap: 1.25rem !default;

/// @deprecated as of v3.x with no replacement

$card-columns-margin: $card-spacer-y !default;

// Card Aspect Ratio

$card-aspect-ratio-label: () !default;
$card-aspect-ratio-label: map-deep-merge(
	(
		display: block,
		margin-bottom: 0.5rem,
		margin-left: 0,
		margin-right: 0,
		margin-top: 0.5rem,
	),
	$card-aspect-ratio-label
);

$card-aspect-ratio-item-top-left: () !default;
$card-aspect-ratio-item-top-left: map-deep-merge(
	(
		left: 1rem,
		top: 1rem,
	),
	$card-aspect-ratio-item-top-left
);

$card-aspect-ratio-item-top-center: () !default;
$card-aspect-ratio-item-top-center: map-deep-merge(
	(
		top: 1rem,
	),
	$card-aspect-ratio-item-top-center
);

$card-aspect-ratio-item-top-right: () !default;
$card-aspect-ratio-item-top-right: map-deep-merge(
	(
		right: 1rem,
		top: 1rem,
	),
	$card-aspect-ratio-item-top-right
);

$card-aspect-ratio-item-right-middle: () !default;
$card-aspect-ratio-item-right-middle: map-deep-merge(
	(
		right: 1rem,
	),
	$card-aspect-ratio-item-right-middle
);

$card-aspect-ratio-item-bottom-right: () !default;
$card-aspect-ratio-item-bottom-right: map-deep-merge(
	(
		bottom: 1rem,
		right: 1rem,
	),
	$card-aspect-ratio-item-bottom-right
);

$card-aspect-ratio-item-bottom-center: () !default;
$card-aspect-ratio-item-bottom-center: map-deep-merge(
	(
		bottom: 1rem,
	),
	$card-aspect-ratio-item-bottom-center
);

$card-aspect-ratio-item-bottom-left: () !default;
$card-aspect-ratio-item-bottom-left: map-deep-merge(
	(
		bottom: 1rem,
		left: 1rem,
	),
	$card-aspect-ratio-item-bottom-left
);

// min-width, see https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106

$card: () !default;
$card: map-deep-merge(
	(
		background-color: $card-bg,
		border-color: $card-border-color,
		border-radius: clay-enable-rounded($card-border-radius),
		border-style: $card-border-style,
		border-width: $card-border-width,
		box-shadow: clay-enable-shadows($card-box-shadow),
		display: block,
		height: $card-height,
		margin-bottom: $card-margin-bottom,
		min-width: 0,
		position: relative,
		word-wrap: break-word,
		aspect-ratio: (
			label: $card-aspect-ratio-label,
		),
		aspect-ratio-item-top-left: $card-aspect-ratio-item-top-left,
		aspect-ratio-item-top-center: $card-aspect-ratio-item-top-center,
		aspect-ratio-item-top-right: $card-aspect-ratio-item-top-right,
		aspect-ratio-item-right-middle: $card-aspect-ratio-item-right-middle,
		aspect-ratio-item-bottom-right: $card-aspect-ratio-item-bottom-right,
		aspect-ratio-item-bottom-center: $card-aspect-ratio-item-bottom-center,
		aspect-ratio-item-bottom-left: $card-aspect-ratio-item-bottom-left,
		hr: (
			margin-left: 0,
			margin-right: 0,
		),
	),
	$card
);

// .card-title

$card-title: () !default;
$card-title: map-deep-merge(
	(
		font-size: 1rem,
		font-weight: $font-weight-semi-bold,
		line-height: $line-height-base,
		margin-bottom: 0,
	),
	$card-title
);

$card-title-link: () !default;
$card-title-link: map-deep-merge(
	(
		color: map-get($card-title, color),
	),
	$card-title-link
);

$card-title: map-deep-merge(
	$card-title,
	(
		href: $card-title-link,
	)
);

// .card-subtitle

$card-subtitle: () !default;
$card-subtitle: map-deep-merge(
	(
		color: $gray-600,
		font-size: 0.875rem,
		font-weight: $font-weight-semi-bold,
		margin-bottom: 0,
		margin-top: 0,
	),
	$card-subtitle
);

$card-subtitle-link: () !default;
$card-subtitle-link: map-deep-merge(
	(
		color: map-get($card-subtitle, color),
		hover: (
			color: clay-darken($gray-600, 15%),
		),
	),
	$card-subtitle-link
);

$card-subtitle: map-deep-merge(
	$card-subtitle,
	(
		href: $card-subtitle-link,
	)
);

// .card-text

$card-text: () !default;
$card-text: map-deep-merge(
	(
		last-child: (
			margin-bottom: 0,
		),
	),
	$card-text
);

// .card-link

$card-link: () !default;
$card-link: map-deep-merge(
	(
		color: $link-color,
		hover: (
			color: $link-hover-color,
			text-decoration: none,
		),
	),
	$card-link
);

// .card-divider

$card-divider-bg: $card-border-color !default;
$card-divider-height: 1px !default;
$card-divider-spacer-x: null !default;
$card-divider-spacer-y: 10px !default;

$card-divider: () !default;
$card-divider: map-merge(
	(
		background-color: $card-divider-bg,
		height: $card-divider-height,
		margin-bottom: $card-divider-spacer-y,
		margin-left: $card-divider-spacer-x,
		margin-right: $card-divider-spacer-x,
		margin-top: $card-divider-spacer-y,
	),
	$card-divider
);

// .card-header

// `margin-bottom`, removes the default margin-bottom of <hN> (h1 - h6)

$card-header: () !default;
$card-header: map-deep-merge(
	(
		background-color: $card-cap-bg,
		border-bottom: $card-border-width solid $card-border-color,
		color: $card-cap-color,
		margin-bottom: 0,
		padding: $card-spacer-y $card-spacer-x,
		first-child: (
			border-radius:
				clay-enable-rounded(
					$card-inner-border-radius $card-inner-border-radius 0 0
				),
		),
	),
	$card-header
);

// .card-footer

$card-footer: () !default;
$card-footer: map-deep-merge(
	(
		background-color: $card-cap-bg,
		border-top: $card-border-width solid $card-border-color,
		padding: $card-spacer-y $card-spacer-x,
		last-child: (
			border-radius:
				clay-enable-rounded(
					0 0 $card-inner-border-radius $card-inner-border-radius
				),
		),
	),
	$card-footer
);

// .card.rounded-*

$card-rounded-inner-border-radius: calc(
	#{$rounded-border-radius} - #{$card-border-width}
) !default;
$card-rounded-0-inner-border-radius: calc(
	#{$rounded-0-border-radius} - #{$card-border-width}
) !default;
$card-rounded-circle-inner-border-radius: calc(
	#{$rounded-circle-border-radius} - #{$card-border-width}
) !default;

// .form-check-card

$form-check-card-checked-box-shadow: $input-btn-focus-box-shadow !default;

$form-check-card: () !default;
$form-check-card: map-deep-merge(
	(
		margin-bottom: $card-margin-bottom,
		margin-top: 0,
		padding-left: 0,
		hover: (
			card: (
				box-shadow: $form-check-card-checked-box-shadow,
			),
		),
		active: (
			card: (
				box-shadow: $form-check-card-checked-box-shadow,
			),
		),
		checked: (
			card: (
				box-shadow: $form-check-card-checked-box-shadow,
			),
		),
		card: (
			margin-bottom: 0,
		),
		form-check-input: (
			margin-left: 0,
			margin-top: 0,
			opacity: 0,
			position: absolute,
			z-index: 1,
		),
		form-check-label: (
			color: $body-color,
			display: inline,
			font-weight: $font-weight-normal,
			padding-left: 0,
			position: static,
		),
		custom-control: (
			display: inline,
			margin-right: 0,
			position: static,
			label: (
				font-weight: $font-weight-normal,
				padding-left: 0,
			),
			custom-control-label: (
				opacity: 0,
				position: absolute,
				z-index: 1,
				before: (
					top: 0,
				),
				after: (
					top: 0,
				),
			),
			custom-control-input: (
				z-index: 2,
				checked: (
					card: (
						box-shadow: $form-check-card-checked-box-shadow,
					),
				),
			),
		),
	),
	$form-check-card
);

// .form-check-bottom-left .card-horizontal > .card-body,
// .form-check-middle-left .card-horizontal > .card-body,
// .form-check-top-left .card-horizontal > .card-body

$checkbox-left-card-padding: 40px !default;

// .form-check-bottom-right .card-horizontal > .card-body,
// .form-check-middle-right .card-horizontal > .card-body,
// .form-check-top-right .card-horizontal > .card-body

$checkbox-right-card-padding: $checkbox-left-card-padding !default;

// Form Check Positions

$checkbox-position: 1rem !default;

// .form-check-bottom-left

$form-check-bottom-left: () !default;
$form-check-bottom-left: map-deep-merge(
	(
		form-check-input: (
			bottom: $checkbox-position,
			left: $checkbox-position,
			opacity: 1,
			top: auto,
			transform: none,
		),
		custom-control: (
			custom-control-input: (
				bottom: $checkbox-position,
				left: $checkbox-position,
				top: auto,
				transform: none,
			),
			custom-control-label: (
				bottom: $checkbox-position,
				left: $checkbox-position,
				opacity: 1,
				top: auto,
				transform: none,
			),
		),
	),
	$form-check-bottom-left
);

// .form-check-bottom-right

$form-check-bottom-right: () !default;
$form-check-bottom-right: map-deep-merge(
	(
		form-check-input: (
			bottom: $checkbox-position,
			left: auto,
			opacity: 1,
			right: $checkbox-position,
			top: auto,
			transform: none,
		),
		custom-control: (
			custom-control-input: (
				bottom: $checkbox-position,
				left: auto,
				right: $checkbox-position,
				top: auto,
				transform: none,
			),
			custom-control-label: (
				bottom: $checkbox-position,
				left: auto,
				opacity: 1,
				right: $checkbox-position,
				top: auto,
				transform: none,
			),
		),
	),
	$form-check-bottom-right
);

// .form-check-middle-left

$form-check-middle-left: () !default;
$form-check-middle-left: map-deep-merge(
	(
		form-check-input: (
			left: $checkbox-position,
			margin-top: 0,
			opacity: 1,
			top: 50%,
			transform: translateY(-50%),
		),
		custom-control: (
			custom-control-input: (
				left: $checkbox-position,
				margin-top: 0,
				top: 50%,
				transform: translateY(-50%),
			),
			custom-control-label: (
				left: $checkbox-position,
				margin-top: 0,
				opacity: 1,
				top: 50%,
				transform: translateY(-50%),
			),
		),
	),
	$form-check-middle-left
);

// .form-check-middle-right

$form-check-middle-right: () !default;
$form-check-middle-right: map-deep-merge(
	(
		form-check-input: (
			left: auto,
			margin-top: 0,
			opacity: 1,
			right: $checkbox-position,
			top: 50%,
			transform: translateY(-50%),
		),
		custom-control: (
			custom-control-input: (
				left: auto,
				margin-top: 0,
				right: $checkbox-position,
				top: 50%,
				transform: translateY(-50%),
			),
			custom-control-label: (
				left: auto,
				margin-top: 0,
				opacity: 1,
				right: $checkbox-position,
				top: 50%,
				transform: translateY(-50%),
			),
		),
	),
	$form-check-middle-right
);

// .form-check-top-right

$form-check-top-right: () !default;
$form-check-top-right: map-deep-merge(
	(
		form-check-input: (
			left: auto,
			opacity: 1,
			right: $checkbox-position,
			top: $checkbox-position,
			transform: none,
		),
		custom-control: (
			custom-control-input: (
				left: auto,
				right: $checkbox-position,
				top: $checkbox-position,
				transform: none,
			),
			custom-control-label: (
				left: auto,
				opacity: 1,
				right: $checkbox-position,
				top: $checkbox-position,
				transform: none,
			),
		),
	),
	$form-check-top-right
);

// .form-check-top-left

$form-check-top-left: () !default;
$form-check-top-left: map-deep-merge(
	(
		form-check-input: (
			left: $checkbox-position,
			opacity: 1,
			top: $checkbox-position,
			transform: none,
		),
		custom-control: (
			custom-control-input: (
				left: $checkbox-position,
				top: $checkbox-position,
				transform: none,
			),
			custom-control-label: (
				left: $checkbox-position,
				opacity: 1,
				top: $checkbox-position,
				transform: none,
			),
		),
	),
	$form-check-top-left
);

// Card Interactive

$card-interactive-after-highlight: () !default;
$card-interactive-after-highlight: map-deep-merge(
	(
		border-radius: 0 0 $card-border-radius $card-border-radius,
		bottom: math-sign($card-border-width),
		content: '',
		height: 0,
		left: math-sign($card-border-width),
		position: absolute,
		right: math-sign($card-border-width),
		transition: height 0.15s ease-out,
		hover: (
			height: 4px,
		),
		focus: (
			height: 4px,
		),
		active: (
			height: 4px,
		),
	),
	$card-interactive-after-highlight
);

$card-interactive-card-body: () !default;
$card-interactive-card-body: map-deep-merge(
	(
		display: block,
	),
	$card-interactive-card-body
);

$card-interactive: () !default;
$card-interactive: map-deep-merge(
	(
		cursor: $link-cursor,
		outline: 0,
		transition: $component-transition,
		hover: (
			background-color: $gray-100,
			text-decoration: none,
		),
		focus: (
			border-color: clay-lighten($component-active-bg, 25%),
			box-shadow: $input-btn-focus-box-shadow,
		),
		active: (
			background-color: $gray-200,
		),
		after-highlight: $card-interactive-after-highlight,
		card-body: $card-interactive-card-body,
	),
	$card-interactive
);

// Card Interactive Primary

$card-interactive-primary-after-highlight: () !default;
$card-interactive-primary-after-highlight: map-deep-merge(
	(
		hover: (
			background-color: $component-active-bg,
		),
		focus: (
			background-color: $component-active-bg,
		),
		active: (
			background-color: $component-active-bg,
		),
	),
	$card-interactive-primary-after-highlight
);

$card-interactive-primary: () !default;
$card-interactive-primary: map-deep-merge(
	(
		focus: (
			background-color: $gray-100,
		),
		active: (
			background-color: $gray-200,
		),
		after-highlight: $card-interactive-primary-after-highlight,
	),
	$card-interactive-primary
);

// Card Interactive Secondary

$card-interactive-secondary: () !default;
$card-interactive-secondary: map-deep-merge(
	(
		color: $gray-900,
		hover: (
			background-color: $white,
			border-color: clay-lighten($component-active-bg, 25%),
			box-shadow: $input-btn-focus-box-shadow,
			color: $gray-900,
		),
		active: (
			background-color: $white,
		),
	),
	$card-interactive-secondary
);

// Card Type Asset

$card-type-asset: () !default;
$card-type-asset: map-deep-merge(
	(
		aspect-ratio: (
			border-color: $card-border-color,
			border-style: solid,
			border-width: 0 0 0.0625rem 0,
			horizontal: 16,
			vertical: 9,
			custom-control: (
				label: (
					bottom: 0,
					cursor: $link-cursor,
					left: 0,
					position: absolute,
					right: 0,
					top: 0,
				),
			),
		),
		card-type-asset-icon: (
			width: 22.225%,
			inline-item: (
				bottom: 0,
				left: 0,
				position: absolute,
				right: 0,
				top: 0,
			),
			lexicon-icon: (
				height: 100%,
				width: 100%,
			),
			sticker: (
				border-radius: 50%,
				display: block,
				font-size: 2vw,
				padding-bottom: 100%,
				width: 100%,
			),
		),
		card-row: (
			align-items: flex-start,
		),
		dropdown-action: (
			margin-right: -0.5rem,
			margin-top: -0.1875rem,
		),
	),
	$card-type-asset
);

// Image Card

$image-card: () !default;

// File Card

$file-card: () !default;
$file-card: map-deep-merge(
	(
		card-type-asset-icon: (
			color: $gray-600,
		),
	),
	$file-card
);

// Product Card

$product-card: () !default;
$product-card: map-deep-merge(
	(
		aspect-ratio: (
			background-color: $white,
			background-image: linear-gradient(0deg, #ebebeb, #ebebeb),
		),
		card-body: (
			text-align: center,
		),
		card-title: (
			font-size: 1rem,
		),
		card-subtitle: (
			font-size: 0.75rem,
		),
		card-text: (
			font-size: 1.125rem,
			font-weight: $font-weight-semi-bold,
		),
	),
	$product-card
);

// User Card

$user-card: () !default;
$user-card: map-deep-merge(
	(
		card-type-asset-icon: (
			max-width: 80px,
			min-width: 48px,
			width: 30%,
			lexicon-icon: (
				height: auto,
				width: 50%,
			),
		),
	),
	$user-card
);

$card-page: () !default;
$card-page: map-deep-merge(
	(
		container-name: c-card-page,
		container-type: inline-size,
		extend: '%clay-custom-grid-wrapper',
	),
	$card-page
);

// Card Page Item Asset

// base: min-width 0, sm min-width: 576px, md: min-width: 768px,
// lg: min-width: 992px

$card-page-item-asset: () !default;
$card-page-item-asset: map-deep-merge(
	(
		flex-basis: 100%,
		max-width: 100%,
		padding-left: $grid-gutter-width * 0.5,
		padding-right: $grid-gutter-width * 0.5,
		'@container #{map-get($card-page, container-name)} (min-width: #{map-get($container-max-widths, sm)})':
		(
			flex-basis: 50%,
			max-width: 50%,
		),
		'@container #{map-get($card-page, container-name)} (min-width: #{map-get($container-max-widths, md)})':
		(
			flex-basis: 33.33333%,
			max-width: 33.33333%,
		),
		'@container #{map-get($card-page, container-name)} (min-width: #{map-get($container-max-widths, lg)})':
		(
			flex-basis: 25%,
			max-width: 25%,
		),
		'@container #{map-get($card-page, container-name)} (min-width: #{map-get($container-max-widths, xl)})':
		(
			flex-basis: 20%,
			max-width: 20%,
		),
	),
	$card-page-item-asset
);

// Card Page Item User

// base: min-width 0, sm min-width: 576px, lg: min-width: 992px

$card-page-item-user: () !default;
$card-page-item-user: map-deep-merge(
	$card-page-item-asset,
	$card-page-item-user
);

// Card Type Directory

$card-type-directory: () !default;
$card-type-directory: map-deep-merge(
	(
		sticker: (
			font-size: 1.125rem,
		),
	),
	$card-type-directory
);

// Card Page Item Directory

$card-page-item-directory: () !default;
$card-page-item-directory: map-deep-merge(
	$card-page-item-asset,
	$card-page-item-directory
);

// Card Type Template

$card-type-template-after-highlight: () !default;

$card-type-template-aspect-ratio: () !default;
$card-type-template-aspect-ratio: map-deep-merge(
	(
		border-width: 0px,
		color: $gray-600,
		horizontal: 16,
		text-align: center,
		vertical: 9,
		lexicon-icon: (
			height: auto,
			width: 28%,
		),
	),
	$card-type-template-aspect-ratio
);

$card-type-template-aspect-ratio-item: () !default;
$card-type-template-aspect-ratio-item: map-deep-merge(
	(
		width: 100.6%,
	),
	$card-type-template-aspect-ratio-item
);

$card-type-template-card-title: () !default;
$card-type-template-card-title: map-deep-merge(
	(
		display: block,
		margin-bottom: 0.5rem,
	),
	$card-type-template-card-title
);

$card-type-template-card-text: () !default;
$card-type-template-card-text: map-deep-merge(
	(
		display: block,
	),
	$card-type-template-card-text
);

$card-type-template: () !default;
$card-type-template: map-deep-merge(
	(
		color: $gray-900,
		hover: (
			color: $gray-900,
		),
		after-highlight: $card-type-template-after-highlight,
		aspect-ratio: $card-type-template-aspect-ratio,
		aspect-ratio-item: $card-type-template-aspect-ratio-item,
		card-title: $card-type-template-card-title,
		card-text: $card-type-template-card-text,
	),
	$card-type-template
);

// Template Card

$template-card-body: () !default;
$template-card-body: map-deep-merge(
	(
		padding-top: 0,
		text-align: center,
	),
	$template-card-body
);

$template-card: () !default;
$template-card: map-deep-merge(
	(
		card-body: $template-card-body,
	),
	$template-card
);

// Template Card Horizontal

$template-card-horizontal-sticker: () !default;
$template-card-horizontal-sticker: map-deep-merge(
	(
		font-size: 1.25rem,
	),
	$template-card-horizontal-sticker
);

$template-card-horizontal-card-row: () !default;
$template-card-horizontal-card-row: map-deep-merge(
	(
		margin-left: -4px,
		margin-right: -4px,
		width: auto,
		autofit-col: (
			padding-left: 4px,
			padding-right: 4px,
		),
	),
	$template-card-horizontal-card-row
);

$template-card-horizontal-card-title: () !default;
$template-card-horizontal-card-title: map-deep-merge(
	(
		color: inherit,
		margin-bottom: 0,
	),
	$template-card-horizontal-card-title
);

$template-card-horizontal: () !default;
$template-card-horizontal: map-deep-merge(
	(
		color: $gray-600,
		hover: (
			color: $gray-600,
		),
		sticker: $template-card-horizontal-sticker,
		card-row: $template-card-horizontal-card-row,
		card-title: $template-card-horizontal-card-title,
	),
	$template-card-horizontal
);
