@if ($enable-bs4-deprecated) {
	.jumbotron {
		background-color: $jumbotron-bg;

		@include border-radius($border-radius-lg);

		color: $jumbotron-color;
		margin-bottom: $jumbotron-padding;
		padding: $jumbotron-padding ($jumbotron-padding * 0.5);

		@include media-breakpoint-up(sm) {
			padding: ($jumbotron-padding * 2) $jumbotron-padding;
		}
	}

	.jumbotron-fluid {
		@include border-radius(0);

		padding-left: 0;
		padding-right: 0;
	}
}
