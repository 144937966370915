.sticker {
	@include clay-sticker-variant($sticker);
}

.sticker-img {
	@extend %aspect-ratio-item-center-middle !optional;
	@extend %aspect-ratio-item-fluid !optional;
}

.sticker-overlay {
	@include clay-css($sticker-overlay);
}

// Sticker Positions

.sticker-bottom-left {
	@include clay-css($sticker-bottom-left);
}

.sticker-bottom-right {
	@include clay-css($sticker-bottom-right);
}

.sticker-top-left {
	@include clay-css($sticker-top-left);
}

.sticker-top-right {
	@include clay-css($sticker-top-right);
}

.sticker-outside {
	@include clay-css($sticker-outside);

	&.sticker-bottom-left {
		@include clay-css(map-get($sticker-outside, sticker-bottom-left));
	}

	&.sticker-bottom-right {
		@include clay-css(map-get($sticker-outside, sticker-bottom-right));
	}

	&.sticker-top-right {
		@include clay-css(map-get($sticker-outside, sticker-top-right));
	}
}

// Sticker User Icon

.sticker-user-icon {
	@include clay-sticker-variant($sticker-user-icon);
}

// Sticker Sizes

@each $size, $value in $sticker-sizes {
	@if not clay-is-map-unset($value) {
		$selector: if(
			starts-with($size, '.') or
				starts-with($size, '#') or
				starts-with($size, '%'),
			$size,
			str-insert($size, '.', 1)
		);

		@if (starts-with($size, '%') or map-get($value, extend)) {
			#{$selector} {
				@include clay-sticker-variant($value);
			}
		} @else if(starts-with($size, 'sticker-')) {
			$placeholder: '%#{$size}';

			#{$placeholder} {
				@include clay-sticker-variant($value);
			}

			#{$selector} {
				@extend #{$placeholder} !optional;
			}
		} @else {
			#{$selector} {
				@include clay-sticker-variant($value);
			}
		}
	}
}

// Sticker Variants

@each $color, $value in $sticker-palette {
	@if not clay-is-map-unset($value) {
		$deprecated-keys: 'primary', 'secondary', 'success', 'info', 'warning',
			'danger', 'dark', 'light';

		$selector: if(
			starts-with($color, '.') or
				starts-with($color, '#') or
				starts-with($color, '%'),
			$color,
			str-insert($color, '.sticker-', 1)
		);

		@if (starts-with($color, '%') or map-get($value, extend)) {
			#{$selector} {
				@include clay-sticker-variant($value);
			}
		} @else if (index($deprecated-keys, '#{$color}') != null) {
			$placeholder: '%sticker-#{$color}';

			#{$placeholder} {
				@include clay-sticker-variant($value);
			}

			#{$selector} {
				@extend #{$placeholder} !optional;
			}
		} @else {
			#{$selector} {
				@include clay-sticker-variant($value);
			}
		}
	}
}

// Sticker Circle

.sticker-circle {
	@include clay-sticker-variant($sticker-circle);
}
