////
/// @group pagination
////

$pagination-bg: $white !default;
$pagination-border-color: $gray-300 !default;
$pagination-border-width: $border-width !default;
$pagination-color: $link-color !default;
$pagination-font-size: null !default;
$pagination-line-height: 1.25 !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y: 0.5rem !default;

$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: $gray-300 !default;
$pagination-hover-color: $link-hover-color !default;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;

$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;
$pagination-active-color: $component-active-color !default;

$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;
$pagination-disabled-color: $gray-600 !default;

// Pagination Item

$pagination-item-height: 2.375rem !default; // 38px

/// @deprecated as of v2 use the Sass map `$pagination-item` instead

$pagination-item-margin-x: -0.5px !default;

/// @deprecated as of v2 use the Sass map `$pagination-item` instead

$pagination-item-margin-y: null !default;

$pagination-item: () !default;
$pagination-item: map-deep-merge(
	(
		margin-bottom: $pagination-item-margin-y,
		margin-left: $pagination-item-margin-x,
		margin-right: $pagination-item-margin-x,
		margin-top: $pagination-item-margin-y,
	),
	$pagination-item
);

// Pagination Link

/// @deprecated as of v2 use the Sass map `$pagination-link` instead

$pagination-link-border-radius: 0px !default;

/// @deprecated as of v2 use the Sass map `$pagination-link` instead

$pagination-link-cursor: pointer !default;

/// @deprecated as of v2 use the Sass map `$pagination-link` instead

$pagination-link-transition: null !default;

$pagination-link-first-border-radius: $border-radius 0 0 $border-radius !default;
$pagination-link-last-border-radius: 0 $border-radius $border-radius 0 !default;

$pagination-margin-bottom: 0.5rem !default;

$pagination-margin-top-mobile: 0.5rem !default;

/// @deprecated as of v2 use the Sass map `$pagination-link-disabled` instead

$pagination-disabled-cursor: $disabled-cursor !default;

/// @deprecated as of v2 use the Sass map `$pagination-link-disabled` instead

$pagination-disabled-opacity: 1 !default;

/// @deprecated as of v2 use the Sass map `$pagination-link-disabled` instead

$pagination-disabled-pointer-events: auto !default;

// .page-link

$pagination-link-hover: () !default;
$pagination-link-hover: map-deep-merge(
	(
		background-color: $pagination-hover-bg,
		border-color: $pagination-hover-border-color,
		color: $pagination-hover-color,
		text-decoration: none,
		z-index: 2,
	),
	$pagination-link-hover
);

$pagination-link-focus: () !default;
$pagination-link-focus: map-deep-merge(
	(
		box-shadow: $pagination-focus-box-shadow,
		outline: $pagination-focus-outline,
		z-index: $zindex-pagination-link-focus,
	),
	$pagination-link-focus
);

$pagination-link-active: () !default;
$pagination-link-active: map-deep-merge(
	(
		background-color: $pagination-active-bg,
		border-color: $pagination-active-border-color,
		color: $pagination-active-color,
		z-index: $zindex-pagination-link-active,
	),
	$pagination-link-active
);

$pagination-link-disabled: () !default;
$pagination-link-disabled: map-deep-merge(
	(
		background-color: $pagination-disabled-bg,
		border-color: $pagination-disabled-border-color,
		box-shadow: none,
		color: $pagination-disabled-color,
		cursor: $pagination-disabled-cursor,
		opacity: $pagination-disabled-opacity,
		pointer-events: $pagination-disabled-pointer-events,
		z-index: $zindex-pagination-link-disabled,
		active: (
			pointer-events: none,
		),
	),
	$pagination-link-disabled
);

$pagination-link: () !default;
$pagination-link: map-deep-merge(
	(
		align-items: center,
		background-color: $pagination-bg,
		border-radius: $pagination-link-border-radius,
		border-color: $pagination-border-color,
		border-style: solid,
		border-width: $pagination-border-width,
		color: $pagination-color,
		cursor: $pagination-link-cursor,
		display: inline-flex,
		height: $pagination-item-height,
		justify-content: center,
		line-height: $pagination-line-height,
		margin-left: 0,
		padding-bottom: $pagination-padding-y,
		padding-left: $pagination-padding-x,
		padding-right: $pagination-padding-x,
		padding-top: $pagination-padding-y,
		position: relative,
		transition: $pagination-link-transition,
		hover: $pagination-link-hover,
		focus: $pagination-link-focus,
		active: $pagination-link-active,
		active-class: $pagination-link-active,
		disabled: $pagination-link-disabled,
		lexicon-icon: (
			margin-top: 0,
		),
	),
	$pagination-link
);

// .page-link-first

$pagination-link-first: () !default;
$pagination-link-first: map-deep-merge(
	(
		border-radius: clay-enable-rounded($pagination-link-first-border-radius),
	),
	$pagination-link-first
);

// .page-link-last

$pagination-link-last: () !default;
$pagination-link-last: map-deep-merge(
	(
		border-radius: clay-enable-rounded($pagination-link-last-border-radius),
	),
	$pagination-link-last
);

// .pagination

$pagination: () !default;
$pagination: map-deep-merge(
	(
		border-radius: clay-enable-rounded($border-radius),
		display: flex,
		flex-wrap: wrap,
		font-size: $pagination-font-size,
		list-style: none,
		margin-bottom: $pagination-margin-bottom,
		padding-left: 0,
	),
	$pagination
);

// .pagination-bar

$pagination-bar: () !default;
$pagination-bar: map-deep-merge(
	(
		align-items: center,
		display: flex,
		flex-wrap: wrap,
		media-breakpoint-down: (
			sm: (
				flex-direction: column,
				justify-content: center,
				pagination: (
					margin-top: $pagination-margin-top-mobile,
				),
			),
		),
	),
	$pagination-bar
);

// Pagination Dropdown Menu

$pagination-dropdown-menu-spacer-y: null !default;

// Pagination Items Per Page

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link` instead

$pagination-items-per-page-bg: null !default;

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link` instead

$pagination-items-per-page-border-color: $pagination-border-color !default;

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link` instead

$pagination-items-per-page-border-radius: $border-radius !default;

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link` instead

$pagination-items-per-page-color: $pagination-color !default;
$pagination-items-per-page-font-size: $pagination-font-size !default;

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link` instead

$pagination-items-per-page-transition: $pagination-link-transition !default;

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link-hover` instead

$pagination-items-per-page-hover-bg: $pagination-hover-bg !default;

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link-hover` instead

$pagination-items-per-page-hover-border-color: $pagination-hover-border-color !default;

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link-hover` instead

$pagination-items-per-page-hover-color: $pagination-hover-color !default;

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link-focus` instead

$pagination-items-per-page-focus-box-shadow: $pagination-focus-box-shadow !default;

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link-focus` instead

$pagination-items-per-page-focus-outline: 0 !default;

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link` instead

$pagination-items-per-page-lexicon-icon-margin-left: 0.125rem !default; // 2px

/// @deprecated as of v2 use the Sass map `$pagination-items-per-page-link` instead

$pagination-items-per-page-lexicon-icon-margin-top: 0.125rem !default; // 2px

// Pagination Items Per Page Link

$pagination-items-per-page-link-hover: () !default;
$pagination-items-per-page-link-hover: map-deep-merge(
	(
		background-color: $pagination-items-per-page-hover-bg,
		border-color: $pagination-items-per-page-hover-border-color,
		color: $pagination-items-per-page-hover-color,
	),
	$pagination-items-per-page-link-hover
);

$pagination-items-per-page-link-focus: () !default;
$pagination-items-per-page-link-focus: map-deep-merge(
	(
		box-shadow: $pagination-items-per-page-focus-box-shadow,
		outline: $pagination-items-per-page-focus-outline,
	),
	$pagination-items-per-page-link-focus
);

$pagination-items-per-page-link-active: () !default;
$pagination-items-per-page-link-active: map-deep-merge(
	(
		background-color: $pagination-active-bg,
		border-color: $pagination-active-border-color,
		color: $pagination-active-color,
	),
	$pagination-items-per-page-link-active
);

$pagination-items-per-page-link-disabled: () !default;
$pagination-items-per-page-link-disabled: map-deep-merge(
	(
		background-color: $pagination-disabled-bg,
		border-color: $pagination-disabled-border-color,
		box-shadow: none,
		color: $pagination-disabled-color,
		cursor: $pagination-disabled-cursor,
		opacity: $pagination-disabled-opacity,
		pointer-events: $pagination-disabled-pointer-events,
	),
	$pagination-items-per-page-link-disabled
);

// .pagination-items-per-page > a, .pagination-items-per-page > button

$pagination-items-per-page-link: () !default;
$pagination-items-per-page-link: map-deep-merge(
	(
		align-items: center,
		background-color: $pagination-items-per-page-bg,
		border-color: $pagination-items-per-page-border-color,
		border-radius: $pagination-items-per-page-border-radius,
		border-style: solid,
		border-width: $pagination-border-width,
		color: $pagination-items-per-page-color,
		display: inline-flex,
		height: $pagination-item-height,
		justify-content: center,
		line-height: $pagination-line-height,
		padding-bottom: $pagination-padding-y,
		padding-left: $pagination-padding-x,
		padding-right: $pagination-padding-x,
		padding-top: $pagination-padding-y,
		text-decoration: none,
		transition: $pagination-items-per-page-transition,
		hover: $pagination-items-per-page-link-hover,
		focus: $pagination-items-per-page-link-focus,
		active: $pagination-items-per-page-link-active,
		disabled: $pagination-items-per-page-link-disabled,
		lexicon-icon: (
			margin-left: $pagination-items-per-page-lexicon-icon-margin-left,
			margin-top: $pagination-items-per-page-lexicon-icon-margin-top,
		),
	),
	$pagination-items-per-page-link
);

// .pagination-items-per-page

$pagination-items-per-page: () !default;
$pagination-items-per-page: map-deep-merge(
	(
		font-size: $pagination-items-per-page-font-size,
		margin-bottom: $pagination-margin-bottom,
		link: $pagination-items-per-page-link,
		media-breakpoint-down: (
			sm: (
				pagination-results: (
					margin-left: auto,
				),
			),
		),
	),
	$pagination-items-per-page
);

// .pagination-results

$pagination-results-color: null !default;

$pagination-results: () !default;
$pagination-results: map-merge(
	(
		border-color: transparent,
		border-style: solid,
		border-width: $pagination-border-width,
		color: $pagination-results-color,
		font-size: $pagination-font-size,
		line-height: $pagination-line-height,
		margin-bottom: $pagination-margin-bottom,
		margin-right: auto,
		max-width: 100%,
		padding: $pagination-padding-y $pagination-padding-x,
		word-wrap: break-word,
	),
	$pagination-results
);

// .pagination-sm

$pagination-font-size-sm: $font-size-sm !default;
$pagination-line-height-sm: 1 !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-sm: 0.25rem !default;

$pagination-item-height-sm: 1.9375rem !default;

$pagination-link-border-radius-sm: $border-radius-sm !default;

$pagination-items-per-page-lexicon-icon-margin-left-sm: null !default;
$pagination-items-per-page-lexicon-icon-margin-top-sm: null !default;

$pagination-sm: () !default;
$pagination-sm: map-deep-merge(
	(
		page-link: (
			font-size: $pagination-font-size-sm,
			height: $pagination-item-height-sm,
			line-height: $pagination-line-height-sm,
			padding-bottom: $pagination-padding-y-sm,
			padding-right: $pagination-padding-x-sm,
			padding-left: $pagination-padding-x-sm,
			padding-top: $pagination-padding-y-sm,
		),
		page-link-first: (
			border-bottom-left-radius: $pagination-link-border-radius-sm,
			border-top-left-radius: $pagination-link-border-radius-sm,
		),
		page-link-last: (
			border-bottom-right-radius: $pagination-link-border-radius-sm,
			border-top-right-radius: $pagination-link-border-radius-sm,
		),
		pagination-items-per-page: (
			border-radius:
				clay-enable-rounded($pagination-link-border-radius-sm),
			link: (
				font-size: $pagination-font-size-sm,
				height: $pagination-item-height-sm,
				line-height: $pagination-line-height-sm,
				padding-bottom: $pagination-padding-y-sm,
				padding-left: $pagination-padding-x,
				padding-right: $pagination-padding-x,
				padding-top: $pagination-padding-y-sm,
			),
			lexicon-icon: (
				margin-left:
					$pagination-items-per-page-lexicon-icon-margin-left-sm,
				margin-right:
					$pagination-items-per-page-lexicon-icon-margin-top-sm,
			),
			pagination-results: (
				font-size: $pagination-font-size-sm,
				line-height: $pagination-line-height-sm,
				padding: $pagination-padding-y-sm $pagination-padding-x,
			),
		),
	),
	$pagination-sm
);

// .pagination-lg

$pagination-font-size-lg: $font-size-lg !default;
$pagination-line-height-lg: 1 !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;

$pagination-item-height-lg: 3.5rem !default;

$pagination-link-border-radius-lg: $border-radius-lg !default;

$pagination-items-per-page-lexicon-icon-margin-left-lg: null !default;
$pagination-items-per-page-lexicon-icon-margin-top-lg: null !default;

$pagination-lg: () !default;
$pagination-lg: map-deep-merge(
	(
		page-link: (
			font-size: $pagination-font-size-lg,
			height: $pagination-item-height-lg,
			line-height: $pagination-line-height-lg,
			padding-bottom: $pagination-padding-y-lg,
			padding-left: $pagination-padding-x-lg,
			padding-right: $pagination-padding-x-lg,
			padding-top: $pagination-padding-y-lg,
		),
		page-link-first: (
			border-bottom-left-radius: $pagination-link-border-radius-lg,
			border-top-left-radius: $pagination-link-border-radius-lg,
		),
		page-link-last: (
			border-bottom-right-radius: $pagination-link-border-radius-lg,
			border-top-right-radius: $pagination-link-border-radius-lg,
		),
		pagination-items-per-page: (
			border-radius:
				clay-enable-rounded($pagination-link-border-radius-lg),
			link: (
				font-size: $pagination-font-size-lg,
				height: $pagination-item-height-lg,
				line-height: $pagination-line-height-lg,
				padding: $pagination-padding-y-lg $pagination-padding-x,
			),
			lexicon-icon: (
				margin-left:
					$pagination-items-per-page-lexicon-icon-margin-left-lg,
				margin-right:
					$pagination-items-per-page-lexicon-icon-margin-top-lg,
			),
			pagination-results: (
				font-size: $pagination-font-size-lg,
				line-height: $pagination-line-height-lg,
				padding: $pagination-padding-y-lg $pagination-padding-x,
			),
		),
	),
	$pagination-lg
);
