.c-empty-state {
	@include clay-css($c-empty-state);
}

.c-empty-state-sm {
	@include clay-css($c-empty-state-sm);
}

.c-empty-state-animation {
	@include clay-css($c-empty-state-animation);
}

// C Empty State Image

.c-empty-state-image {
	@include clay-css($c-empty-state-image);
}

.c-empty-state-aspect-ratio {
	@include clay-css($c-empty-state-aspect-ratio);
}

// C Empty State Title

.c-empty-state-title {
	@include clay-css($c-empty-state-title);
}

// C Empty State Text

.c-empty-state-text {
	@include clay-css($c-empty-state-text);
}

// C Empty State Footer

.c-empty-state-footer {
	@include clay-css($c-empty-state-footer);
}

// C Empty State Small

.c-empty-state-sm {
	@include clay-css($c-empty-state-sm);

	&.c-empty-state-animation {
		@include clay-css(map-get($c-empty-state-sm, c-empty-state-animation));
	}

	.c-empty-state-image {
		@include clay-css(map-get($c-empty-state-sm, c-empty-state-image));
	}

	.c-empty-state-title {
		@include clay-css(map-get($c-empty-state-sm, c-empty-state-title));
	}

	.c-empty-state-text {
		@include clay-css(map-get($c-empty-state-sm, c-empty-state-text));
	}

	.c-empty-state-footer {
		@include clay-css(map-get($c-empty-state-sm, c-empty-state-footer));
	}
}
