////
/// @group customForms
////

/// A mixin to help create `custom-control-input` variants.
/// @param {Map} $map - A map of `key: value` pairs. The keys and value types are listed below:
/// @example
/// $var: (
///     enabled: {Bool}, // Set to false to prevent mixin styles from being output. Default: true
///     custom-control-label: ( // N/A
///         before: (), // Pseudo element that contains the checkbox/radio
///         after: (), // Pseudo element that contains the checkbox/radio icon
///     ),
///     hover: ( // N/A
///         custom-control-label: ( // N/A
///             before: (),
///             after: (),
///         ),
///     ),
///     focus: ( // N/A
///         custom-control-label: ( // N/A
///             before: (),
///             after: (),
///         ),
///     ),
///     active: ( // N/A
///         custom-control-label: ( // N/A
///             before: (),
///             after: (),
///         ),
///     ),
///     disabled: (
///         // &:disabled
///         custom-control-label: (
///             // &:disabled ~ .custom-control-label
///             before: (),
///             after: (),
///         ),
///     ),
///     checked: ( // N/A
///         custom-control-label: ( // N/A
///             before: (),
///             after: (),
///         ),
///         hover: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///         ),
///         focus: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///         ),
///         active: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///         ),
///         disabled: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///         ),
///     ),
///     indeterminate: ( // N/A
///         custom-control-label: ( // N/A
///             before: (),
///             after: (),
///         ),
///         hover: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///         ),
///         focus: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///         ),
///         active: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///         ),
///         disabled: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///         ),
///     ),
/// );

@mixin clay-custom-control-input-variant($map) {
	@if (type-of($map) == 'map') {
		$enabled: setter(map-get($map, enabled), true);

		@if ($enabled) {
			@if (length($map) != 0) {
				@include clay-css($map);
			}

			$_custom-control-label: map-get($map, custom-control-label);

			@if ($_custom-control-label) {
				~ .custom-control-label {
					@include clay-css($_custom-control-label);

					$_before: map-get($_custom-control-label, before);

					@if ($_before) {
						&::before {
							@include clay-css($_before);
						}
					}

					$_after: map-get($_custom-control-label, after);

					@if ($_after) {
						&::after {
							@include clay-css($_after);
						}
					}
				}
			}

			$_card: map-get($map, card);

			@if ($_card) {
				~ .card {
					@include clay-card-variant($_card);
				}
			}

			$_hover: map-get($map, hover);

			@if ($_hover) {
				&:hover {
					@include clay-css($_hover);

					$_custom-control-label: map-get(
						$_hover,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_hover, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_focus: map-get($map, focus);

			@if ($_focus) {
				@at-root {
					#{$focus-visible-selector},
					#{if($c-prefers-focus-selector,$c-prefers-focus-selector,clay-insert-before('.cadmin', '.c-prefers-focus ', '&:focus'))} {
						$_custom-control-label: map-get(
							$_focus,
							custom-control-label
						);

						@if ($_custom-control-label) {
							~ .custom-control-label {
								@include clay-css($_custom-control-label);

								$_before: map-get(
									$_custom-control-label,
									before
								);

								@if ($_before) {
									&::before {
										@include clay-css($_before);
									}
								}

								$_after: map-get($_custom-control-label, after);

								@if ($_after) {
									&::after {
										@include clay-css($_after);
									}
								}
							}
						}

						$_card: map-get($_focus, card);

						@if ($_card) {
							~ .card {
								@include clay-card-variant($_card);
							}
						}
					}
				}
			}

			$_active: map-get($map, active);

			@if ($_active) {
				&:active {
					$_custom-control-label: map-get(
						$_active,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_active, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			// Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247

			$_disabled: map-get($map, disabled);

			@if ($_disabled) {
				&[disabled],
				&:disabled {
					@include clay-css($_disabled);

					$_custom-control-label: map-get(
						$_disabled,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_disabled, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_readonly: map-get($map, readonly);

			@if ($_readonly) {
				&[readonly] {
					@include clay-css($_readonly);

					$_custom-control-label: map-get(
						$_readonly,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_readonly, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_readonly-disabled: if(
				$_readonly,
				map-get($_readonly, disabled),
				null
			);

			@if ($_readonly-disabled) {
				&[readonly][disabled] {
					@include clay-css($_readonly-disabled);

					$_custom-control-label: map-get(
						$_readonly-disabled,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_readonly-disabled, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_checked: map-get($map, checked);

			@if ($_checked) {
				&:checked {
					$_custom-control-label: map-get(
						$_checked,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_checked, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_checked-hover: if($_checked, map-get($_checked, hover), null);

			@if ($_checked-hover) {
				&:checked:hover {
					$_custom-control-label: map-get(
						$_checked-hover,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_checked-hover, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_checked-focus: if($_checked, map-get($_checked, focus), null);

			@if ($_checked-focus) {
				@at-root {
					&:checked {
						#{$focus-visible-selector},
						#{if($c-prefers-focus-selector,$c-prefers-focus-selector,clay-insert-before('.cadmin', '.c-prefers-focus ', '&:focus'))} {
							$_custom-control-label: map-get(
								$_checked-focus,
								custom-control-label
							);

							@if ($_custom-control-label) {
								~ .custom-control-label {
									@include clay-css($_custom-control-label);

									$_before: map-get(
										$_custom-control-label,
										before
									);

									@if ($_before) {
										&::before {
											@include clay-css($_before);
										}
									}

									$_after: map-get(
										$_custom-control-label,
										after
									);

									@if ($_after) {
									}
									&::after {
										@include clay-css($_after);
									}
								}
							}

							$_card: map-get($_checked-focus, card);

							@if ($_card) {
								~ .card {
									@include clay-card-variant($_card);
								}
							}
						}
					}
				}
			}

			$_checked-active: if($_checked, map-get($_checked, active), null);

			@if ($_checked-active) {
				&:checked:active {
					$_custom-control-label: map-get(
						$_checked-active,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_checked-active, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant(
								map-deep-get($map, checked, active, card)
							);
						}
					}
				}
			}

			$_checked-disabled: if(
				$_checked,
				map-get($_checked, disabled),
				null
			);

			@if ($_checked-disabled) {
				&:checked[disabled],
				&:checked:disabled {
					@include clay-css($_checked-disabled);

					$_custom-control-label: map-get(
						$_checked-disabled,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_checked-disabled, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_checked-readonly: if(
				$_checked,
				map-get($_checked, readonly),
				null
			);

			@if ($_checked-readonly) {
				&:checked[readonly] {
					@include clay-css($_checked-readonly);

					$_custom-control-label: map-get(
						$_checked-readonly,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_checked-readonly, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_checked-readonly-disabled: if(
				$_checked-readonly,
				map-get($_checked-readonly, disabled),
				null
			);

			@if ($_checked-readonly-disabled) {
				&:checked[readonly][disabled] {
					@include clay-css($_checked-readonly-disabled);

					$_custom-control-label: map-get(
						$_checked-readonly-disabled,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_checked-readonly-disabled, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_indeterminate: map-get($map, indeterminate);

			@if ($_indeterminate) {
				&:indeterminate {
					@include clay-css($_indeterminate);

					$_custom-control-label: map-get(
						$_indeterminate,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_indeterminate, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_indeterminate-hover: if(
				$_indeterminate,
				map-get($_indeterminate, hover),
				null
			);

			@if ($_indeterminate-hover) {
				&:indeterminate:hover {
					@include clay-css($_indeterminate-hover);

					$_custom-control-label: map-get(
						$_indeterminate-hover,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_indeterminate-hover, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_indeterminate-focus: if(
				$_indeterminate,
				map-get($_indeterminate, focus),
				null
			);

			@if ($_indeterminate-focus) {
				@at-root {
					&:indeterminate {
						#{$focus-visible-selector},
						#{if($c-prefers-focus-selector,$c-prefers-focus-selector,clay-insert-before('.cadmin', '.c-prefers-focus ', '&:focus'))} {
							@include clay-css($_indeterminate-focus);

							$_custom-control-label: map-get(
								$_indeterminate-focus,
								custom-control-label
							);

							@if ($_custom-control-label) {
								~ .custom-control-label {
									@include clay-css($_custom-control-label);

									$_before: map-get(
										$_custom-control-label,
										before
									);

									@if ($_before) {
										&::before {
											@include clay-css($_before);
										}
									}

									$_after: map-get(
										$_custom-control-label,
										after
									);

									@if ($_after) {
										&::after {
											@include clay-css($_after);
										}
									}
								}
							}

							$_card: map-get($_indeterminate-focus, card);

							@if ($_card) {
								~ .card {
									@include clay-card-variant($_card);
								}
							}
						}
					}
				}
			}

			$_indeterminate-active: if(
				$_indeterminate,
				map-get($_indeterminate, active),
				null
			);

			@if ($_indeterminate-active) {
				&:indeterminate:active {
					@include clay-css($_indeterminate-active);

					$_custom-control-label: map-get(
						$_indeterminate-active,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_indeterminate-active, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_indeterminate-disabled: if(
				$_indeterminate,
				map-get($_indeterminate, disabled),
				null
			);

			@if ($_indeterminate-disabled) {
				&:indeterminate[disabled],
				&:indeterminate:disabled {
					@include clay-css($_indeterminate-disabled);

					$_custom-control-label: map-get(
						$_indeterminate-disabled,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_indeterminate-disabled, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_indeterminate-readonly: if(
				$_indeterminate,
				map-get($_indeterminate, readonly),
				null
			);

			@if ($_indeterminate-readonly) {
				&:indeterminate[readonly] {
					@include clay-css($_indeterminate-readonly);

					$_custom-control-label: map-get(
						$_indeterminate-readonly,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_indeterminate-readonly, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}

			$_indeterminate-readonly-disabled: if(
				$_indeterminate-readonly,
				map-get($_indeterminate-readonly, disabled),
				null
			);

			@if ($_indeterminate-readonly-disabled) {
				&:indeterminate[readonly][disabled] {
					@include clay-css($_indeterminate-readonly-disabled);

					$_custom-control-label: map-get(
						$_indeterminate-readonly-disabled,
						custom-control-label
					);

					@if ($_custom-control-label) {
						~ .custom-control-label {
							@include clay-css($_custom-control-label);

							$_before: map-get($_custom-control-label, before);

							@if ($_before) {
								&::before {
									@include clay-css($_before);
								}
							}

							$_after: map-get($_custom-control-label, after);

							@if ($_after) {
								&::after {
									@include clay-css($_after);
								}
							}
						}
					}

					$_card: map-get($_indeterminate-readonly-disabled, card);

					@if ($_card) {
						~ .card {
							@include clay-card-variant($_card);
						}
					}
				}
			}
		}
	}
}

/// A mixin to help create `custom-control` variants. This is used by `custom-checkbox` and `custom-radio`.
/// @param {Map} $map - A map of `key: value` pairs. The keys and value types are listed below:
/// @example
/// $var: (
///     enabled: {Bool}, // Set to false to prevent mixin styles from being output. Default: true
///     label: (
///         // .your-variant-name label
///     ),
///     custom-control-label: (
///         before: (), // Pseudo element that contains the checkbox/radio
///         after: (), // Pseudo element that contains the checkbox/radio icon
///     ),
///     custom-control-label-text: (
///         // .your-variant-name .custom-control-label-text
///         small: (
///             // .your-variant-name .custom-control-label-text small,
///             // .your-variant-name .custom-control-label-text .small
///         ),
///     ),
///     custom-control-input: (
///         // The map `custom-control-input` is passed to the `clay-custom-control-input-variant` mixin.
///         // .your-variant-name .custom-control-input
///         enabled: {Bool}, // Set to false to prevent mixin styles from being output. Default: true
///         custom-control-label: ( // N/A
///             before: (),
///             after: (),
///         ),
///         hover: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///         ),
///         focus: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///         ),
///         active: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///         ),
///         disabled: (
///             // &:disabled
///             custom-control-label: (
///                 // &:disabled ~ .custom-control-label
///                 before: (),
///                 after: (),
///             ),
///         ),
///         checked: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///             hover: ( // N/A
///                 custom-control-label: ( // N/A
///                     before: (),
///                     after: (),
///                 ),
///             ),
///             focus: ( // N/A
///                 custom-control-label: ( // N/A
///                     before: (),
///                     after: (),
///                 ),
///             ),
///             active: ( // N/A
///                 custom-control-label: ( // N/A
///                     before: (),
///                     after: (),
///                 ),
///             ),
///             disabled: ( // N/A
///                 custom-control-label: ( // N/A
///                     before: (),
///                     after: (),
///                 ),
///             ),
///         ),
///         indeterminate: ( // N/A
///             custom-control-label: ( // N/A
///                 before: (),
///                 after: (),
///             ),
///             hover: ( // N/A
///                 custom-control-label: ( // N/A
///                     before: (),
///                     after: (),
///                 ),
///             ),
///             focus: ( // N/A
///                 custom-control-label: ( // N/A
///                     before: (),
///                     after: (),
///                 ),
///             ),
///             active: ( // N/A
///                 custom-control-label: ( // N/A
///                     before: (),
///                     after: (),
///                 ),
///             ),
///             disabled: ( // N/A
///                 custom-control-label: ( // N/A
///                     before: (),
///                     after: (),
///                 ),
///             ),
///         ),
///     ),
/// );

@mixin clay-custom-control-variant($map) {
	@if (type-of($map) == 'map') {
		$enabled: setter(map-get($map, enabled), true);

		@if ($enabled) {
			@if (length($map) != 0) {
				@include clay-css($map);
			}

			$_label: map-get($map, label);

			@if ($_label) {
				label {
					@include clay-css($_label);
				}
			}

			$_custom-control-label: map-get($map, custom-control-label);

			@if ($_custom-control-label) {
				.custom-control-label {
					@include clay-css($_custom-control-label);

					$_before: map-get($_custom-control-label, before);

					@if ($_before) {
						&::before {
							@include clay-css($_before);
						}
					}

					$_after: map-get($_custom-control-label, after);

					@if ($_after) {
						&::after {
							@include clay-css($_after);
						}
					}
				}
			}

			$_custom-control-label-text: map-get(
				$map,
				custom-control-label-text
			);

			@if ($_custom-control-label-text) {
				.custom-control-label-text {
					@include clay-css($_custom-control-label-text);

					$_small: map-get($_custom-control-label-text, small);

					@if ($_small) {
						small,
						.small {
							@include clay-css($_small);
						}
					}
				}
			}

			$_custom-control-input: map-get($map, custom-control-input);

			@if ($_custom-control-input) {
				.custom-control-input {
					@include clay-custom-control-input-variant(
						$_custom-control-input
					);
				}
			}
		}
	}
}
