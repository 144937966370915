/// @deprecated as of v3.x with no replacement

$jumbotron-bg: $gray-200 !default;

/// @deprecated as of v3.x with no replacement

$jumbotron-color: null !default;

/// @deprecated as of v3.x with no replacement

$jumbotron-padding: 2rem !default;
