$table-bg: $white !default;
$table-accent-bg: $light-l1 !default;
$table-hover-bg: clay-lighten($component-active-bg, 44.9) !default;
$table-border-color: $gray-300 !default;
$table-font-size: 0.875rem !default;

$table-disabled-color: $gray-500 !default;

$table-head-bg: $white !default;
$table-head-border-bottom-width: 0 !default;
$table-head-color: $gray-600 !default;
$table-head-font-weight: $font-weight-semi-bold !default;

$table-head-link: () !default;
$table-head-link: map-deep-merge(
	(
		border-radius: 1px,
		color: $gray-600,
		transition: box-shadow 0.15s ease-in-out,
		hover: (
			color: $gray-900,
		),
		focus: (
			box-shadow: $component-focus-box-shadow,
			outline: 0,
		),
	),
	$table-head-link
);

// Table Body

$c-table-tbody: () !default;

// Table Footer

$c-table-tfoot: () !default;

$table-caption-color: $gray-900 !default;

$table-divider-bg: $gray-200 !default;
$table-divider-color: $gray-600 !default;
$table-divider-font-size: 0.75rem !default; // 12px
$table-divider-font-weight: $font-weight-semi-bold !default;
$table-divider-text-transform: uppercase !default;

$table-quick-action-menu-accent-bg: $table-accent-bg !default;
$table-quick-action-menu-accent-active-bg: $table-hover-bg !default;
$table-quick-action-menu-active-bg: $gray-200 !default;
$table-quick-action-menu-hover-bg: $table-hover-bg !default;

// Table Title

$table-title-link: () !default;
$table-title-link: map-deep-merge(
	(
		border-radius: 1px,
		display: inline-block,
		max-width: 100%,
		transition: box-shadow 0.15s ease-in-out,
		focus: (
			box-shadow: $component-focus-box-shadow,
			outline: 0,
		),
	),
	$table-title-link
);

$table-title: () !default;
$table-title: map-deep-merge(
	(
		color: $gray-900,
		font-size: 0.875rem,
	),
	$table-title
);

// Table Action Link

$table-action-link: () !default;
$table-action-link: map-deep-merge(
	(
		color: $gray-600,
		font-size: 1rem,
		transition: $component-transition,
		hover: (
			background-color: rgba(0, 0, 0, 0.02),
			color: $gray-900,
		),
		focus: (
			background-color: rgba(0, 0, 0, 0.02),
			color: $gray-900,
			box-shadow: $component-focus-box-shadow,
			outline: 0,
		),
		active: (
			background-color: rgba(0, 0, 0, 0.04),
		),
	),
	$table-action-link
);

// Table Link

$table-link: () !default;
$table-link: map-deep-merge(
	(
		border-radius: 1px,
		color: $gray-900,
		display: inline-block,
		max-width: 100%,
		transition: box-shadow 0.15s ease-in-out,
		hover: (
			color: $gray-900,
		),
		focus: (
			box-shadow: $component-focus-box-shadow,
			outline: 0,
		),
	),
	$table-link
);

// .table-focus

$c-tr-table-focus: () !default;
$c-tr-table-focus: map-deep-merge(
	(
		td: (
			before: (
				box-shadow: (
					inset 0 0.125rem 0 0 $primary-l0,
					inset 0 0.25rem 0 0 $white,
					inset 0 -0.125rem 0 0 $primary-l0,
					inset 0 -0.25rem 0 0 $white,
				),
			),
			first-child: (
				after: (
					box-shadow: (
						inset 0.125rem 0 0 0 $primary-l0,
						inset 0.25rem 0 0 0 $white,
					),
				),
			),
			last-child: (
				after: (
					box-shadow: (
						inset -0.125rem 0 0 0 $primary-l0,
						inset -0.25rem 0 0 0 $white,
					),
				),
			),
		),
	),
	$c-tr-table-focus
);

// Table List

$table-list-border-color: $gray-300 !default;
$table-list-color: $body-color !default;

$table-list-accent-bg: $gray-100 !default;
$table-list-hover-bg: $table-hover-bg !default;
$table-list-active-bg: $table-list-hover-bg !default;

$table-list-disabled-color: $gray-500 !default;

$table-list-head-bg: $white !default;

// Table List Title

$table-list-title: () !default;
$table-list-title: map-deep-merge(
	(
		color: $gray-900,
		font-size: 0.875rem,
		line-height: 1.45,
	),
	$table-list-title
);

$table-list-title-link: () !default;
$table-list-title-link: map-deep-merge(
	(
		border-radius: 1px,
		color: $gray-900,
		display: inline-block,
		max-width: 100%,
		transition: box-shadow 0.15s ease-in-out,
		hover: (
			color: $gray-900,
		),
		focus: (
			box-shadow: $component-focus-box-shadow,
			outline: 0,
		),
	),
	$table-list-title-link
);

// Table List Link

$table-list-link: () !default;
$table-list-link: map-deep-merge(
	(
		border-radius: 1px,
		color: $gray-900,
		display: inline-block,
		max-width: 100%,
		transition: box-shadow 0.15s ease-in-out,
		hover: (
			color: $gray-900,
		),
		focus: (
			box-shadow: $component-focus-box-shadow,
			outline: 0,
		),
	),
	$table-list-link
);

// Table List Action Link

$table-list-action-link: () !default;
$table-list-action-link: map-deep-merge(
	(
		color: $gray-600,
		font-size: 1rem,
		transition: $component-transition,
		hover: (
			background-color: rgba(0, 0, 0, 0.02),
			color: $gray-900,
		),
		focus: (
			background-color: rgba(0, 0, 0, 0.02),
			color: $gray-900,
			box-shadow: $component-focus-box-shadow,
			outline: 0,
		),
		active: (
			background-color: rgba(0, 0, 0, 0.04),
		),
	),
	$table-list-action-link
);
