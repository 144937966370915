.multi-step-nav {
	align-items: flex-end;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin-bottom: $multi-step-nav-margin-bottom;
	padding: $multi-step-nav-padding-y $multi-step-nav-padding-x;
	position: relative;
}

.multi-step-indicator-label-bottom {
	.multi-step-item {
		padding-bottom: calc(
			#{$multi-step-indicator-label-font-size} * #{$line-height-base}
		);
	}

	.multi-step-indicator-label {
		top: $multi-step-icon-size;
	}
}

.multi-step-indicator-label-top {
	.multi-step-item {
		padding-top: calc(
			#{$multi-step-indicator-label-font-size} * #{$line-height-base}
		);
	}

	.multi-step-indicator-label {
		bottom: calc(#{$multi-step-icon-size} + #{$multi-step-divider-height});
	}
}

.multi-step-title-center {
	@include clay-css($multi-step-title-center);

	.multi-step-title {
		@include clay-text-typography(
			map-get($multi-step-title-center, multi-step-title)
		);
	}
}

.multi-step-item {
	margin-bottom: $multi-step-item-margin-bottom;
	position: relative;

	&:last-child {
		.multi-step-divider {
			display: none;
		}
	}

	&.active {
		.multi-step-icon {
			background-color: $multi-step-icon-active-bg;
			background-image: $multi-step-icon-active-bg-image;
			background-position: $multi-step-icon-active-bg-position;
			background-repeat: no-repeat;
			background-size: $multi-step-icon-active-bg-size;
			color: $multi-step-icon-active-color;

			@if ($multi-step-icon-active-bg-image) {
				&[data-multi-step-icon] {
					&:before {
						// https://issues.liferay.com/browse/LPS-147457

						content: none;
					}
				}
			}
		}

		.multi-step-divider {
			background-color: $multi-step-divider-active-bg;
		}
	}

	&.active.complete {
		.multi-step-icon {
			background-color: $multi-step-icon-active-bg;
			color: $multi-step-icon-active-color;
		}
	}

	&.complete {
		.dropdown .multi-step-icon {
			&,
			&:active {
				background-image: none;
			}
		}

		.multi-step-icon {
			background-color: $multi-step-icon-complete-bg;
			color: $multi-step-icon-complete-color;

			@if ($multi-step-icon-complete-bg-image) {
				background-image: $multi-step-icon-complete-bg-image;
				background-position: $multi-step-icon-complete-bg-position;
				background-repeat: no-repeat;
				background-size: $multi-step-icon-complete-bg-size;

				&[data-multi-step-icon] {
					&:before {
						// https://issues.liferay.com/browse/LPS-147457

						content: none;
					}
				}

				// BS4 resets background-image in buttons https://github.com/twbs/bootstrap/issues/23353

				&:active {
					background-image: $multi-step-icon-complete-bg-image;
				}
			}
		}

		.multi-step-divider {
			background-color: $multi-step-divider-complete-bg;
		}
	}

	&.error {
		@include clay-css($multi-step-item-error);

		.multi-step-title {
			@include clay-text-typography(
				map-get($multi-step-item-error, multi-step-title)
			);
		}

		.multi-step-icon {
			$_multi-step-icon: setter(
				map-get($multi-step-item-error, multi-step-icon)
			);

			@include clay-css($_multi-step-icon);

			&[data-multi-step-icon] {
				$_data-multi-step-icon: setter(
					map-get($_multi-step-icon, data-multi-step-icon),
					()
				);

				&::before {
					@include clay-css(map-get($_data-multi-step-icon, before));
				}
			}

			.lexicon-icon {
				@include clay-css(map-get($_multi-step-icon, lexicon-icon));
			}
		}

		.multi-step-divider {
			@include clay-css(
				map-get($multi-step-item-error, multi-step-divider)
			);
		}
	}

	&.disabled {
		.multi-step-title {
			@include clay-text-typography($multi-step-title-disabled);
		}

		.multi-step-icon {
			background-color: $multi-step-icon-disabled-bg;
			background-image: $multi-step-icon-disabled-bg-image;
			background-position: $multi-step-icon-disabled-bg-position;
			background-repeat: no-repeat;
			background-size: $multi-step-icon-disabled-bg-size;
			color: $multi-step-icon-disabled-color;
			cursor: $multi-step-icon-disabled-cursor;
			opacity: $multi-step-icon-disabled-opacity;

			@if ($multi-step-icon-disabled-bg-image) {
				&[data-multi-step-icon] {
					&:before {
						// https://issues.liferay.com/browse/LPS-147457

						content: none;
					}
				}
			}

			@at-root {
				&.focus,
				#{$focus-visible-selector},
				.c-prefers-focus &:focus {
					box-shadow: $multi-step-icon-disabled-focus-box-shadow;
				}
			}
		}

		.multi-step-indicator-label {
			color: $multi-step-indicator-label-disabled-color;
		}

		.multi-step-divider {
			background-color: $multi-step-divider-disabled-bg;
		}
	}
}

.multi-step-title {
	@include clay-text-typography($multi-step-title);
}

.multi-step-item-expand {
	flex-grow: 1;
	width: $multi-step-item-width;
}

.multi-step-divider {
	background-color: $multi-step-divider-bg;
	height: $multi-step-divider-height;
	margin-left: $multi-step-icon-size;
	margin-top: math-sign($multi-step-divider-height);
	position: relative;
	top: $multi-step-divider-top;
}

.multi-step-indicator {
	@include clay-css($multi-step-indicator);

	.dropdown-item-indicator,
	.dropdown-item-indicator-start,
	.dropdown-item-indicator-end {
		display: none;
	}

	.complete {
		.dropdown-item-indicator,
		.dropdown-item-indicator-start,
		.dropdown-item-indicator-end {
			display: block;
		}
	}

	.multi-step-indicator-label {
		@include clay-css(
			map-get($multi-step-indicator, multi-step-indicator-label)
		);
	}
}

.multi-step-icon {
	align-items: center;
	background-color: $multi-step-icon-bg;
	background-position: center;
	background-repeat: no-repeat;
	background-size: $multi-step-icon-font-size;
	border-color: $multi-step-icon-border-color;
	border-radius: $multi-step-icon-border-radius;
	border-style: $multi-step-icon-border-style;
	border-width: $multi-step-icon-border-width;
	color: $multi-step-icon-color;
	cursor: $multi-step-icon-cursor;
	display: flex;
	font-size: $multi-step-icon-font-size;
	font-weight: $multi-step-icon-font-weight;
	justify-content: center;
	height: $multi-step-icon-size;
	padding-bottom: $multi-step-icon-padding-bottom;
	padding-left: $multi-step-icon-padding-left;
	padding-right: $multi-step-icon-padding-right;
	padding-top: $multi-step-icon-padding-top;
	position: relative;

	@include transition($multi-step-icon-transition);

	width: $multi-step-icon-size;

	&:hover {
		background-color: $multi-step-icon-hover-bg;
		color: $multi-step-icon-hover-color;
		text-decoration: $multi-step-icon-hover-text-decoration;
	}

	@at-root {
		&.focus,
		#{$focus-visible-selector},
		.c-prefers-focus &:focus {
			background-color: $multi-step-icon-focus-bg;
			box-shadow: $multi-step-icon-focus-box-shadow;
			color: $multi-step-icon-focus-color;
			outline: $multi-step-icon-focus-outline;
			text-decoration: $multi-step-icon-focus-text-decoration;
		}
	}

	&[data-multi-step-icon]::before {
		content: $data-multi-step-icon-before-content;
		height: $multi-step-icon-size;
		line-height: $multi-step-icon-size;
		text-align: center;
		width: $multi-step-icon-size;
	}

	@if ($enable-c-inner) {
		.c-inner {
			margin-bottom: #{math-sign($multi-step-icon-padding-bottom)};
			margin-left: #{math-sign($multi-step-icon-padding-left)};
			margin-right: #{math-sign($multi-step-icon-padding-right)};
			margin-top: #{math-sign($multi-step-icon-padding-top)};
			position: absolute;
		}
	}

	.btn-link {
		vertical-align: baseline;
	}

	.lexicon-icon {
		height: $multi-step-icon-font-size;
		margin-top: 0;
		width: $multi-step-icon-font-size;
	}
}

.multi-step-item-fixed-width {
	&.multi-step-item,
	.multi-step-item-expand {
		flex-grow: 0;
		width: $multi-step-item-fixed-width;
	}
}

.multi-step-nav-center {
	@include clay-css($multi-step-nav-center);

	.multi-step-item {
		$_item: setter(map-get($multi-step-nav-center, multi-step-item), ());

		@include clay-css($_item);
	}

	.multi-step-divider {
		@include clay-css(map-get($multi-step-nav-center, multi-step-divider));
	}

	.multi-step-indicator {
		@include clay-css(
			map-get($multi-step-nav-center, multi-step-indicator)
		);
	}

	.multi-step-title {
		@include clay-css(map-get($multi-step-nav-center, multi-step-title));
	}
}

.multi-step-nav-collapse-sm {
	@include media-breakpoint-down(sm) {
		flex-wrap: nowrap;

		&.multi-step-indicator-label-top {
			.multi-step-indicator-label {
				bottom: auto;
				top: 0;
			}
		}

		&.multi-step-indicator-label-bottom {
			.multi-step-indicator-label {
				bottom: 0;
				top: auto;
			}
		}

		&.multi-step-title-center {
			.multi-step-title {
				margin-left: 0;
				max-width: none;
				transform: none;
			}
		}

		&.multi-step-nav-center {
			.multi-step-item {
				flex-grow: 0;
				width: auto;
			}

			.multi-step-item-expand {
				flex-grow: 1;
				width: 75px;
			}

			.multi-step-divider {
				left: auto;
				margin-left: $multi-step-icon-size;
				width: auto;
			}

			.multi-step-indicator {
				left: auto;
				transform: none;
			}
		}

		.active {
			&.multi-step-item {
				position: static;
			}

			.multi-step-title {
				display: block;
				left: 0;
				position: absolute;
				right: 0;
				text-align: center;
				top: 0;
			}

			.multi-step-indicator {
				position: static;
			}

			.multi-step-indicator-label {
				display: block;
				left: 0;
				max-width: none;
				position: absolute;
				right: 0;
				text-align: center;
				transform: none;
				width: auto;
			}
		}

		.multi-step-item {
			margin-bottom: 0;
			min-width: $multi-step-icon-size;
			padding-top: calc(
				#{$multi-step-title-font-size} + #{$multi-step-title-margin-bottom} +
					(#{$multi-step-divider-height} * 0.5)
			);
		}

		.multi-step-title {
			display: none;
		}

		.multi-step-indicator-label {
			display: none;
		}
	}
}
