$c-horizontal-resizer: () !default;
$c-horizontal-resizer: map-merge(
	(
		background-color: transparent,
		bottom: 0,
		cursor: ew-resize,
		margin-right: -0.25rem,
		position: absolute,
		right: 0,
		top: 0,
		user-select: none,
		width: 0.5rem,
		z-index: 10,
		hover: (
			background-color: $primary-l0,
		),
		focus: (
			background-color: $primary-l0,
		),
	),
	$c-horizontal-resizer
);
