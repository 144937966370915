$thumbnail-bg: $body-bg !default;
$thumbnail-border-color: $gray-300 !default;
$thumbnail-border-width: $border-width !default;

$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075) !default;
$thumbnail-padding: 0.25rem !default;

$figure-caption-color: $gray-600 !default;
$figure-caption-font-size: 90% !default;
