$tooltip-bg: $black !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-box-shadow: null !default;
$tooltip-color: $white !default;
$tooltip-font-size: $font-size-sm !default;
$tooltip-margin: 0 !default;
$tooltip-max-width: 200px !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-x: 0.5rem !default;
$tooltip-padding-y: 0.25rem !default;

$tooltip-arrow-color: $tooltip-bg !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-offset: 0.25rem !default;
$tooltip-arrow-width: 0.8rem !default;

$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;
$form-feedback-tooltip-font-size: $tooltip-font-size !default;
$form-feedback-tooltip-line-height: $line-height-base !default;
$form-feedback-tooltip-opacity: $tooltip-opacity !default;
$form-feedback-tooltip-padding-x: $tooltip-padding-x !default;
$form-feedback-tooltip-padding-y: $tooltip-padding-y !default;

// .tooltip

$tooltip: () !default;
$tooltip: map-deep-merge(
	(
		display: block,
		font-family: $font-family-base,
		font-size: $tooltip-font-size,
		font-style: normal,
		font-weight: $font-weight-normal,
		letter-spacing: normal,
		line-break: auto,
		line-height: $line-height-base,
		margin: $tooltip-margin,
		opacity: 0,
		position: absolute,
		text-align: left,
		text-decoration: none,
		text-shadow: none,
		text-transform: none,
		white-space: normal,
		word-break: normal,
		word-spacing: normal,
		word-wrap: break-word,
		z-index: $zindex-tooltip,
		show: (
			opacity: $tooltip-opacity,
		),
		arrow: (
			display: block,
			height: $tooltip-arrow-height,
			position: absolute,
			width: $tooltip-arrow-width,
			before: (
				border-color: transparent,
				border-style: solid,
				content: '',
				position: absolute,
			),
		),
	),
	$tooltip
);

// .tooltip-inner

$tooltip-inner: () !default;
$tooltip-inner: map-merge(
	(
		background-color: $tooltip-bg,
		border-radius: clay-enable-rounded($tooltip-border-radius),
		box-shadow: clay-enable-shadows($tooltip-box-shadow),
		color: $tooltip-color,
		max-width: $tooltip-max-width,
		padding: $tooltip-padding-y $tooltip-padding-x,
		text-align: center,
		white-space: pre-line,
	),
	$tooltip-inner
);

// .clay-tooltip-bottom, .clay-tooltip-bottom-left, .clay-tooltip-bottom-right

$clay-tooltip-bottom: () !default;
$clay-tooltip-bottom: map-deep-merge(
	(
		padding-top: $tooltip-arrow-height,
		arrow: (
			left: 50%,
			margin-left: math-sign($tooltip-arrow-width * 0.5),
			top: 0,
			before: (
				border-bottom-color: $tooltip-arrow-color,
				border-width: 0
					(
						$tooltip-arrow-width * 0.5,
					)
					$tooltip-arrow-height,
				bottom: 0,
			),
		),
		tooltip-arrow: (
			border-bottom-color: transparent,
			border-right-color: transparent,
			left: 50%,
			top: 0,
		),
	),
	$clay-tooltip-bottom
);

// .clay-tooltip-bottom-left

$clay-tooltip-bottom-left: () !default;
$clay-tooltip-bottom-left: map-deep-merge(
	(
		arrow: (
			left: $tooltip-arrow-offset,
			margin-left: 0,
		),
	),
	$clay-tooltip-bottom-left
);

// .clay-tooltip-bottom-right

$clay-tooltip-bottom-right: () !default;
$clay-tooltip-bottom-right: map-deep-merge(
	(
		arrow: (
			left: auto,
			margin-left: 0,
			right: $tooltip-arrow-offset,
		),
	),
	$clay-tooltip-bottom-right
);

// .clay-tooltip-left, .clay-tooltip-left-bottom, .clay-tooltip-left-top

$clay-tooltip-left: () !default;
$clay-tooltip-left: map-deep-merge(
	(
		padding-right: $tooltip-arrow-height,
		arrow: (
			height: $tooltip-arrow-width,
			margin-top: math-sign($tooltip-arrow-width * 0.5),
			right: 0,
			top: 50%,
			width: $tooltip-arrow-height,
			before: (
				border-left-color: $tooltip-arrow-color,
				border-width: (
					$tooltip-arrow-width * 0.5,
				)
				0
				(
					$tooltip-arrow-width * 0.5,
				)
				$tooltip-arrow-height,
				left: 0,
			),
		),
		tooltip-arrow: (
			border-bottom-color: transparent,
			border-left-color: transparent,
			right: 0,
			top: 50%,
		),
	),
	$clay-tooltip-left
);

// .clay-tooltip-left-bottom

$clay-tooltip-left-bottom: () !default;
$clay-tooltip-left-bottom: map-deep-merge(
	(
		arrow: (
			bottom: $tooltip-arrow-offset,
			margin-top: 0,
			top: auto,
		),
	),
	$clay-tooltip-left-bottom
);

// .clay-tooltip-left-top

$clay-tooltip-left-top: () !default;
$clay-tooltip-left-top: map-deep-merge(
	(
		arrow: (
			margin-top: 0,
			top: $tooltip-arrow-offset,
		),
	),
	$clay-tooltip-left-top
);

// .clay-tooltip-right, .clay-tooltip-right-bottom, .clay-tooltip-right-top

$clay-tooltip-right: () !default;
$clay-tooltip-right: map-deep-merge(
	(
		padding-left: $tooltip-arrow-height,
		arrow: (
			height: $tooltip-arrow-width,
			left: 0,
			margin-top: math-sign($tooltip-arrow-width * 0.5),
			width: $tooltip-arrow-height,
			top: 50%,
			before: (
				border-right-color: $tooltip-arrow-color,
				border-width: (
					$tooltip-arrow-width * 0.5,
				)
				$tooltip-arrow-height
				(
					$tooltip-arrow-width * 0.5,
				)
				0,
				right: 0,
			),
		),
		tooltip-arrow: (
			border-right-color: transparent,
			border-top-color: transparent,
			left: 0,
			top: 50%,
		),
	),
	$clay-tooltip-right
);

// .clay-tooltip-right-bottom

$clay-tooltip-right-bottom: () !default;
$clay-tooltip-right-bottom: map-deep-merge(
	(
		arrow: (
			bottom: $tooltip-arrow-offset,
			margin-top: 0,
			top: auto,
		),
	),
	$clay-tooltip-right-bottom
);

// .clay-tooltip-right-top

$clay-tooltip-right-top: () !default;
$clay-tooltip-right-top: map-deep-merge(
	(
		arrow: (
			margin-top: 0,
			top: $tooltip-arrow-offset,
		),
	),
	$clay-tooltip-right-top
);

// .clay-tooltip-top, .clay-tooltip-top-left, .clay-tooltip-top-right

$clay-tooltip-top: () !default;
$clay-tooltip-top: map-deep-merge(
	(
		padding-bottom: $tooltip-arrow-height,
		arrow: (
			bottom: 0,
			left: 50%,
			margin-left: math-sign($tooltip-arrow-width * 0.5),
			before: (
				border-top-color: $tooltip-arrow-color,
				border-width: $tooltip-arrow-height
					(
						$tooltip-arrow-width * 0.5,
					)
					0,
				top: 0,
			),
		),
		tooltip-arrow: (
			border-left-color: transparent,
			border-top-color: transparent,
			bottom: 0,
			left: 50%,
		),
	),
	$clay-tooltip-top
);

// .clay-tooltip-top-left

$clay-tooltip-top-left: () !default;
$clay-tooltip-top-left: map-deep-merge(
	(
		arrow: (
			left: $tooltip-arrow-offset,
			margin-left: 0,
		),
	),
	$clay-tooltip-top-left
);

// .clay-tooltip-top-right

$clay-tooltip-top-right: () !default;
$clay-tooltip-top-right: map-deep-merge(
	(
		arrow: (
			left: auto,
			margin-left: 0,
			right: $tooltip-arrow-offset,
		),
	),
	$clay-tooltip-top-right
);
