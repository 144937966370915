$c-slideout-sidebar-width: 320px !default;

$c-slideout-sidebar-width-mobile: 280px !default;

$c-slideout-tbar-stacked-min-width: 40px !default;

$c-slideout-tbar-stacked-min-width-mobile: 40px !default;

// C Slideout Transitions

$c-slideout-transition-in: () !default;
$c-slideout-transition-in: map-merge(
	(
		transition: all 0.3s ease-in-out,
	),
	$c-slideout-transition-in
);

$c-slideout-transition-out: () !default;
$c-slideout-transition-out: map-merge(
	(
		transition: all 0.2s ease-in,
	),
	$c-slideout-transition-out
);

// C Slideout Positions

$c-slideout-absolute: () !default;
$c-slideout-absolute: map-merge(
	(
		position: absolute,
	),
	$c-slideout-absolute
);

$c-slideout-fixed: () !default;
$c-slideout-fixed: map-merge(
	(
		position: fixed,
	),
	$c-slideout-fixed
);

// C Slideout Base

$c-slideout: () !default;
$c-slideout: map-deep-merge(
	(
		display: flex,
		overflow: hidden,
		z-index: $zindex-c-slideout,
		c-slideout-shown: (
			overflow: visible,
		),
		sidebar: (
			display: none,
			flex-shrink: 0,
			overflow: visible,
			position: relative,
			width: $c-slideout-sidebar-width,
		),
		sidebar-c-slideout-show: (
			display: block,
		),
		sidebar-c-slideout-transition: (
			display: block,
			c-horizontal-resizer: (
				display: none,
			),
		),
		tbar-stacked: (
			display: none,
			flex-shrink: 0,
			min-width: $c-slideout-tbar-stacked-min-width,
			overflow: auto,
			position: relative,
			z-index: 1,
		),
		tbar-stacked-c-slideout-show: (
			display: flex,
		),
		tbar-stacked-c-slideout-transition: (
			display: flex,
		),
	),
	$c-slideout
);

$c-slideout-sm-down: () !default;
$c-slideout-sm-down: map-deep-merge(
	(
		sidebar: (
			width: $c-slideout-sidebar-width-mobile,
		),
	),
	$c-slideout-sm-down
);

// C Slideout Start

$c-slideout-start: () !default;
$c-slideout-start: map-deep-merge(
	(
		bottom: 0,
		left: 0,
		top: 0,
		c-slideout-tbar-shown-sidebar: (
			left: math-sign($c-slideout-sidebar-width),
		),
		sidebar: (
			left: #{math-sign($c-slideout-sidebar-width) +
				math-sign($c-slideout-tbar-stacked-min-width)},
			width: $c-slideout-sidebar-width,
		),
		sidebar-c-slideout-show: (
			left: 0,
		),
		tbar-stacked: (
			left: math-sign($c-slideout-tbar-stacked-min-width),
		),
		tbar-stacked-c-slideout-show: (
			left: 0,
		),
	),
	$c-slideout-start
);

$c-slideout-start-sm-down: () !default;
$c-slideout-start-sm-down: map-deep-merge(
	(
		c-slideout-tbar-shown-sidebar: (
			left: math-sign($c-slideout-sidebar-width-mobile),
		),
		sidebar: (
			left: #{math-sign($c-slideout-sidebar-width-mobile) +
				math-sign($c-slideout-tbar-stacked-min-width-mobile)},
			width: $c-slideout-sidebar-width-mobile,
		),
		tbar-stacked: (
			left: math-sign($c-slideout-tbar-stacked-min-width-mobile),
		),
	),
	$c-slideout-start-sm-down
);

// C Slideout End

$c-slideout-end: () !default;
$c-slideout-end: map-deep-merge(
	(
		bottom: 0,
		right: 0,
		top: 0,
		c-slideout-tbar-shown-sidebar: (
			right: math-sign($c-slideout-sidebar-width),
		),
		sidebar: (
			right: #{math-sign($c-slideout-sidebar-width) +
				math-sign($c-slideout-tbar-stacked-min-width)},
		),
		sidebar-c-slideout-show: (
			right: 0,
		),
		tbar-stacked: (
			right: math-sign($c-slideout-tbar-stacked-min-width),
		),
		tbar-stacked-c-slideout-show: (
			right: 0,
		),
		c-horizontal-resizer: (
			left: 0,
			right: auto,
		),
	),
	$c-slideout-end
);

$c-slideout-end-sm-down: () !default;
$c-slideout-end-sm-down: map-deep-merge(
	(
		c-slideout-tbar-shown-sidebar: (
			right: math-sign($c-slideout-sidebar-width-mobile),
		),
		sidebar: (
			right: #{math-sign($c-slideout-sidebar-width-mobile) +
				math-sign($c-slideout-tbar-stacked-min-width-mobile)},
		),
		tbar-stacked: (
			right: math-sign($c-slideout-tbar-stacked-min-width-mobile),
		),
	),
	$c-slideout-end-sm-down
);

$c-slideout-height-full: () !default;
$c-slideout-height-full: map-deep-merge(
	(
		z-index: $zindex-c-slideout-height-full,
	),
	$c-slideout-height-full
);
