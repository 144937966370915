.treeview {
	@include clay-css($treeview);

	.btn {
		@include clay-button-variant(map-get($treeview, btn));
	}

	.btn-monospaced {
		@include clay-button-variant(map-get($treeview, btn-monospaced));
	}

	.custom-control {
		@include clay-css(map-get($treeview, custom-control));
	}

	.component-expander {
		@include clay-button-variant(map-get($treeview, component-expander));

		.lexicon-icon:not(.component-expanded-d-none) {
			display: none;
		}
	}

	.component-action {
		@include clay-button-variant(map-get($treeview, component-action));
	}

	.component-icon {
		@include clay-css(map-get($treeview, component-icon));

		.lexicon-icon {
			@include clay-css(
				map-deep-get($treeview, component-icon, lexicon-icon)
			);
		}
	}

	.component-text {
		@include clay-css(map-get($treeview, component-text));

		> .autofit-row {
			@include clay-css(
				map-deep-get($treeview, component-text, autofit-row)
			);

			> .autofit-col {
				@include clay-css(
					map-deep-get(
						$treeview,
						component-text,
						autofit-row,
						autofit-col
					)
				);
			}
		}
	}

	&.show-component-expander-on-hover {
		@include clay-css($treeview-show-component-expander-on-hover);

		&:hover,
		&.hover {
			@include clay-css(
				map-get($treeview-show-component-expander-on-hover, hover)
			);

			.component-expander {
				@include clay-css(
					map-deep-get(
						$treeview-show-component-expander-on-hover,
						hover,
						component-expander
					)
				);
			}
		}

		.treeview-link {
			@include clay-css(
				map-get(
					$treeview-show-component-expander-on-hover,
					treeview-link
				)
			);

			&:focus,
			&.focus {
				@include clay-css(
					map-deep-get(
						$treeview-show-component-expander-on-hover,
						treeview-link,
						focus
					)
				);

				.component-expander {
					@include clay-css(
						map-deep-get(
							$treeview-show-component-expander-on-hover,
							treeview-link,
							focus,
							component-expander
						)
					);
				}
			}
		}

		.component-expander {
			@include clay-css(
				map-get(
					$treeview-show-component-expander-on-hover,
					component-expander
				)
			);
		}
	}

	.quick-action-item {
		@include clay-css(map-get($treeview, quick-action-item));
	}

	.autofit-row {
		@include clay-css(map-get($treeview, autofit-row));
	}

	&.show-quick-actions-on-hover {
		.treeview-link {
			&:not(:hover):not(:focus):not(.focus) {
				.quick-action-item {
					display: none;
				}
			}

			&:disabled,
			&.disabled {
				.quick-action-item {
					display: none;
				}
			}

			.quick-action-item {
				&:disabled,
				&.disabled {
					display: none;
				}
			}
		}
	}
}

.treeview-group {
	@include clay-css(map-get($treeview, treeview-group));
}

.treeview-item {
	@include clay-css(map-get($treeview, treeview-item));

	&:last-child {
		@include clay-css(map-deep-get($treeview, treeview-item, last-child));
	}
}

.treeview-dropping-indicator-top {
	@include clay-link(
		map-deep-get($treeview, treeview-dropping-indicator-top)
	);
}

.treeview-dropping-indicator-bottom {
	@include clay-link(
		map-deep-get($treeview, treeview-dropping-indicator-bottom)
	);
}

.treeview-dropping-indicator-over {
	@include clay-link(
		map-deep-get($treeview, treeview-dropping-indicator-over)
	);
}

.treeview-link {
	@include clay-link(map-get($treeview, treeview-link));

	&.treeview-dropping-middle {
		@include clay-link(
			map-deep-get($treeview, treeview-link, treeview-dropping-middle)
		);
	}

	&.show,
	&[aria-expanded='true'] {
		.component-expander {
			.component-expanded-d-none {
				display: none;
			}

			.lexicon-icon:not(.component-expanded-d-none) {
				display: inline-block;
			}
		}
	}

	> .autofit-row,
	.c-inner > .autofit-row {
		@include clay-css(map-deep-get($treeview, treeview-link, autofit-row));

		> .autofit-col {
			@include clay-css(
				map-deep-get($treeview, treeview-link, autofit-row, autofit-col)
			);
		}
	}
}

.treeview-nested-margins {
	@include clay-css($treeview-nested-margins);

	.treeview-group {
		@include clay-css(map-get($treeview-nested-margins, treeview-group));

		.treeview-item {
			@include clay-css(
				map-deep-get(
					$treeview-nested-margins,
					treeview-group,
					treeview-item
				)
			);
		}
	}
}

.treeview-item-dragging {
	@include clay-css(map-get($treeview, treeview-item-dragging));

	.treeview-link {
		@include clay-link(
			map-deep-get($treeview, treeview-item-dragging, treeview-link)
		);
	}
}

.treeview-dragging {
	@include clay-css(map-get($treeview, treeview-dragging));
}

// Treeview Variants

.treeview-light {
	@include clay-css($treeview-light);

	.btn {
		@include clay-button-variant(map-get($treeview-light, btn));
	}

	.btn-monospaced {
		@include clay-button-variant(map-get($treeview-light, btn-monospaced));
	}

	.component-expander {
		@include clay-button-variant(
			map-get($treeview-light, component-expander)
		);

		&.btn-secondary {
			@include clay-button-variant(
				map-deep-get($treeview-light, component-expander, btn-secondary)
			);
		}
	}

	.custom-control {
		@include clay-css(map-get($treeview-light, custom-control));
	}

	.treeview-group {
		@include clay-css(map-get($treeview-light, treeview-group));
	}

	.treeview-item {
		@include clay-css(map-get($treeview-light, treeview-item));

		&:last-child {
			@include clay-css(
				map-deep-get($treeview-light, treeview-item, last-child)
			);
		}
	}

	.treeview-link {
		@include clay-link(map-get($treeview-light, treeview-link));

		&:disabled,
		&.disabled {
			.component-expander {
				@include clay-css(
					map-deep-get(
						$treeview-light,
						treeview-link,
						disabled,
						component-expander
					)
				);
			}

			.component-action {
				@include clay-css(
					map-deep-get(
						$treeview-light,
						treeview-link,
						disabled,
						component-action
					)
				);
			}
		}

		&.treeview-no-hover {
			@include clay-link(
				map-deep-get($treeview-light, treeview-link, treeview-no-hover)
			);
		}
	}

	.component-action {
		@include clay-button-variant(
			map-get($treeview-light, component-action)
		);
	}

	.component-icon {
		@include clay-css(map-get($treeview-light, component));
	}

	.component-text {
		@include clay-css(map-get($treeview-light, component));
	}
}

.treeview-dark {
	@include clay-css($treeview-dark);

	.btn {
		@include clay-button-variant(map-get($treeview-dark, btn));
	}

	.btn-monospaced {
		@include clay-button-variant(map-get($treeview-dark, btn-monospaced));
	}

	.component-expander {
		@include clay-button-variant(
			map-get($treeview-dark, component-expander)
		);

		&.btn-secondary {
			@include clay-button-variant(
				map-deep-get($treeview-dark, component-expander, btn-secondary)
			);
		}
	}

	.custom-control {
		@include clay-css(map-get($treeview-dark, custom-control));
	}

	.treeview-group {
		@include clay-css(map-get($treeview-dark, treeview-group));
	}

	.treeview-item {
		@include clay-css(map-get($treeview-dark, treeview-item));

		&:last-child {
			@include clay-css(
				map-deep-get($treeview-dark, treeview-item, last-child)
			);
		}
	}

	.treeview-link {
		@include clay-link(map-get($treeview-dark, treeview-link));

		&:disabled,
		&.disabled {
			.component-expander {
				@include clay-css(
					map-deep-get(
						$treeview-dark,
						treeview-link,
						disabled,
						component-expander
					)
				);
			}

			.component-action {
				@include clay-css(
					map-deep-get(
						$treeview-dark,
						treeview-link,
						disabled,
						component-action
					)
				);
			}
		}

		&.treeview-no-hover {
			@include clay-link(
				map-deep-get($treeview-dark, treeview-link, treeview-no-hover)
			);
		}
	}

	.component-action {
		@include clay-button-variant(map-get($treeview-dark, component-action));
	}

	.component-icon {
		@include clay-css(map-get($treeview-dark, component-icon));
	}

	.component-text {
		@include clay-css(map-get($treeview-dark, component-text));
	}
}
