.navigation-bar {
	@include clay-navbar-size($navigation-bar-size);
	@include clay-navbar-variant($navigation-bar-base);
}

.navigation-bar-light {
	@include clay-navbar-variant($navigation-bar-light);
}

.navigation-bar-secondary {
	@include clay-navbar-variant($navigation-bar-secondary);
}
