.application-bar {
	flex-wrap: nowrap;

	@include clay-navbar-size($application-bar-size);
	@include clay-navbar-variant($application-bar-base);

	.container,
	.container-fluid {
		flex-wrap: nowrap;
	}

	.navbar-nav {
		flex-wrap: nowrap;
	}
}

.application-bar-dark {
	@include clay-navbar-variant($application-bar-dark);
}
