.badge {
	@include clay-badge-variant($badge);

	// Inline Item in Badges are deprecated in v2.0.0-rc.11 use .badge-item
	// pattern instead

	.inline-item {
		a {
			align-items: center;
			display: inline-flex;
			margin-top: $badge-lexicon-icon-margin-top;
		}

		.btn-unstyled {
			color: inherit;
			display: inline-flex;
			margin-top: $badge-lexicon-icon-margin-top;
			max-width: none;
		}

		.close {
			color: inherit;
			display: inline-flex;
			float: none;
			font-size: inherit;
			margin-top: $badge-lexicon-icon-margin-top;
			opacity: 1;
		}

		a,
		.btn-unstyled,
		.close {
			.lexicon-icon {
				margin-top: 0;
			}
		}

		.lexicon-icon {
			height: $badge-lexicon-icon-height;
			margin-top: $badge-lexicon-icon-margin-top;
			width: $badge-lexicon-icon-width;
		}
	}
}

.badge-item {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	justify-content: center;
	min-height: 0;
	position: relative;
	text-align: left;

	a {
		display: inline-flex;
	}

	.btn-unstyled {
		color: inherit;
		display: inline-flex;
	}

	.close {
		@include clay-close($badge-close);
	}

	.lexicon-icon {
		height: $badge-lexicon-icon-height;
		margin-top: $badge-lexicon-icon-margin-top;
		width: $badge-lexicon-icon-width;
	}
}

.badge-item-expand {
	flex-grow: 1;
	flex-shrink: 1;
	min-width: $badge-item-expand-min-width;
	word-wrap: break-word;

	a {
		flex-direction: column;
	}
}

.badge-item-before {
	margin-right: $badge-item-spacer-x;
}

.badge-item-after {
	margin-left: $badge-item-spacer-x;
}

// Quick fix for badges in buttons

.btn .badge {
	margin-right: 0;
	position: relative;
	top: -1px;
}

// Badge Pill

.badge-pill {
	@include clay-badge-variant($badge-pill);
}

// Badge Variants

@each $color, $value in $badge-palette {
	@if not clay-is-map-unset($value) {
		$deprecated-keys: 'primary', 'secondary', 'success', 'info', 'warning',
			'danger', 'dark', 'light';

		$selector: if(
			index($deprecated-keys, '#{$color}') != null,
			str-insert($color, '.badge-', 1),
			if(starts-with($color, 'badge'), str-insert($color, '.', 1), $color)
		);

		@if (starts-with($color, '%') or map-get($value, extend)) {
			#{$selector} {
				@include clay-badge-variant($value);
			}
		} @else if
			(
				starts-with($color, 'badge') or
					index($deprecated-keys, '#{$color}') !=
					null
			)
		{
			$placeholder: if(
				starts-with($color, '.') or starts-with($color, '#'),
				'%#{str-slice($color, 2)}',
				'%badge-#{$color}'
			);

			#{$placeholder} {
				@include clay-badge-variant($value);
			}

			#{$selector} {
				@extend #{$placeholder} !optional;
			}
		} @else {
			#{$selector} {
				@include clay-badge-variant($value);
			}
		}
	}
}
