// Responsive images (ensure images don't scale beyond their parents)
// This is purposefully opt-in via an explicit class rather than being the default for all `<img>`s.
// We previously tried the "images are responsive by default" approach in Bootstrap v2,
// and abandoned it in Bootstrap v3 because it breaks lots of third-party widgets (including Google Maps)
// which weren't expecting the images within themselves to be involuntarily resized.
// See also https://github.com/twbs/bootstrap/issues/18178

.img-fluid {
	height: auto;
	max-width: 100%;
}

// Image thumbnails

.img-thumbnail {
	background-color: $thumbnail-bg;
	border: $thumbnail-border-width solid $thumbnail-border-color;

	@include border-radius($thumbnail-border-radius);
	@include box-shadow($thumbnail-box-shadow);

	height: auto;
	max-width: 100%;
	padding: $thumbnail-padding;
}

// Figures

.figure {
	// Ensures the caption's text aligns with the image.

	display: inline-block;
}

.figure-img {
	line-height: 1;
	margin-bottom: $spacer * 0.5;
}

.figure-caption {
	color: $figure-caption-color;
	font-size: $figure-caption-font-size;
}
