$quick-action-font-size: null !default;
$quick-action-padding-x: null !default;
$quick-action-padding-y: null !default;

$quick-action-item-margin-x: 0.5rem !default; // 8px
$quick-action-item-margin-y: null !default;
$quick-action-item-padding-x: null !default; // 8px
$quick-action-item-padding-y: null !default; // 8px

$quick-action-item-min-height: 2rem !default; // 32px
$quick-action-item-min-width: 2rem !default; // 32px

$quick-action-item-border-radius: $border-radius !default;

$quick-action-item-disabled-cursor: $disabled-cursor !default;
$quick-action-item-disabled-opacity: 0.65 !default;
