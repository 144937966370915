// .dropdown-menu

// @deprecated as of v3.x use map $dropdown-menu instead

$dropdown-bg: $white !default;

// @deprecated as of v3.x use map $dropdown-menu instead

$dropdown-border-color: $gray-300 !default;

// @deprecated as of v3.x use map $dropdown-menu instead

$dropdown-border-width: 0px !default;

// @deprecated as of v3.x use map $dropdown-menu instead

$dropdown-box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3) !default;

// @deprecated as of v3.x use map $dropdown-menu instead

$dropdown-font-size: 0.875rem !default; // 14px

// @deprecated as of v3.x use map $dropdown-menu instead

$dropdown-min-height: 40px !default;

// @deprecated as of v3.x use map $dropdown-menu instead

$dropdown-min-width: 240px !default;

// @deprecated as of v3.x use map $dropdown-menu instead

$dropdown-max-width: 240px !default;

$dropdown-padding-y: 0.375rem !default; // 6px
$dropdown-spacer: 0.3125rem !default; // 5px

$dropdown-menu: () !default;
$dropdown-menu: map-deep-merge(
	(
		background-color: $dropdown-bg,
		border-color: $dropdown-border-color,
		border-width: $dropdown-border-width,
		box-shadow: clay-enable-shadows($dropdown-box-shadow),
		font-size: $dropdown-font-size,
		margin: $dropdown-spacer 0 0,
		max-width: $dropdown-max-width,
		min-height: $dropdown-min-height,
		min-width: $dropdown-min-width,
		padding: $dropdown-padding-y 0,
	),
	$dropdown-menu
);

// .dropdown-item

$dropdown-item-padding-x: 1rem !default;
$dropdown-item-padding-y: 0.34375rem !default;

/// @deprecated as of v3.x use map $dropdown-item instead

$dropdown-link-color: $gray-600 !default;

/// @deprecated as of v3.x use map $dropdown-item instead

$dropdown-link-font-weight: $font-weight-normal !default;

/// @deprecated as of v3.x use map $dropdown-item instead

$dropdown-link-hover-color: $gray-900 !default;

/// @deprecated as of v3.x use map $dropdown-item instead

$dropdown-link-hover-bg: clay-lighten($component-active-bg, 44.9) !default;

/// @deprecated as of v3.x use map $dropdown-item instead

$dropdown-link-active-color: $dropdown-link-hover-color !default;

/// @deprecated as of v3.x use map $dropdown-item instead

$dropdown-link-active-bg: $dropdown-link-hover-bg !default;

/// @deprecated as of v3.x use map $dropdown-item instead

$dropdown-link-active-font-weight: null !default;

/// @deprecated as of v3.x use map $dropdown-item instead

$dropdown-link-disabled-color: $gray-500 !default;

// Weird box-shadow inset with border-radius render in IE https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/12515080/

$dropdown-item-base: () !default;
$dropdown-item-base: map-deep-merge(
	(
		font-size: inherit,
		focus: (
			box-shadow: $component-focus-inset-box-shadow,
			outline: 0,
			text-decoration: none,
		),
		active-class: (
			c-kbd-inline: (
				color: $dropdown-link-active-color,
			),
		),
		disabled: (
			box-shadow: none,
		),
		c-kbd-inline: (
			color: $gray-500,
		),
	),
	$dropdown-item-base
);

// .dropdown-header

// @deprecated as of v3.x use map $dropdown-header instead

$dropdown-header-color: $gray-900 !default;

// @deprecated as of v3.x use map $dropdown-header instead

$dropdown-header-font-size: 0.875rem !default; // 14px

// @deprecated as of v3.x use map $dropdown-header instead

$dropdown-header-margin-top: 0.625rem !default; // 10px

// @deprecated as of v3.x use map $dropdown-header instead

$dropdown-header-padding-y: 0.34375rem !default;

$dropdown-header: () !default;
$dropdown-header: map-deep-merge(
	(
		color: $dropdown-header-color,
		font-size: $dropdown-header-font-size,
		margin-top: $dropdown-header-margin-top,
	),
	$dropdown-header
);

// .dropdown-subheader

// @deprecated as of v3.x use map $dropdown-subheader instead

$dropdown-subheader-font-size: 0.75rem !default; // 12px

// @deprecated as of v3.x use map $dropdown-subheader instead

$dropdown-subheader-margin-top: 0.625rem !default; // 10px

// @deprecated as of v3.x use map $dropdown-subheader instead

$dropdown-subheader-padding-y: 0.375rem !default;

$dropdown-subheader: () !default;
$dropdown-subheader: map-deep-merge(
	(
		font-size: $dropdown-subheader-font-size,
		margin-top: $dropdown-subheader-margin-top,
	),
	$dropdown-subheader
);

// .dropdown-caption

// @deprecated as of v3.x use map $dropdown-caption instead

$dropdown-caption-color: $gray-600 !default;

$dropdown-caption: () !default;
$dropdown-caption: map-merge(
	(
		color: $dropdown-caption-color,
	),
	$dropdown-caption
);

// .dropdown-divider

// @deprecated as of v3.x use map $dropdown-divider instead

$dropdown-divider-bg: $dropdown-border-color !default;

$dropdown-divider: () !default;
$dropdown-divider: map-merge(
	(
		background-color: $dropdown-divider-bg,
	),
	$dropdown-divider
);

// .dropdown-section

// @deprecated as of v3.x use map $dropdown-section instead

$dropdown-section-custom-control-label: () !default;
$dropdown-section-custom-control-label: map-deep-merge(
	(
		color: $secondary,
	),
	$dropdown-section-custom-control-label
);

// @deprecated as of v3.x use map $dropdown-section instead

$dropdown-section-active-custom-control-label: () !default;
$dropdown-section-active-custom-control-label: map-deep-merge(
	(
		color: $gray-900,
	),
	$dropdown-section-active-custom-control-label
);

// @deprecated as of v3.x use map $dropdown-section instead

$dropdown-section-custom-control-label-text: () !default;
$dropdown-section-custom-control-label-text: map-deep-merge(
	(
		padding-left: 0.75rem,
	),
	$dropdown-section-custom-control-label-text
);

$dropdown-section: () !default;
$dropdown-section: map-deep-merge(
	(
		padding: 0.375rem $dropdown-item-padding-x,
		custom-control-label: $dropdown-section-custom-control-label,
		custom-control-label-text: $dropdown-section-custom-control-label-text,
		active: (
			custom-control-label: $dropdown-section-active-custom-control-label,
		),
		custom-control-outside: (
			label: (
				padding-left: 1.75rem,
			),
		),
	),
	$dropdown-section
);

// Dropdown Item Indicator

$dropdown-item-indicator-size: 1rem !default;
$dropdown-item-indicator-spacer-x: 0.75rem !default;

// Autocomplete Dropdown Menu

$autocomplete-dropdown-menu: () !default;
$autocomplete-dropdown-menu: map-deep-merge(
	(
		max-height: 10rem,
	),
	$autocomplete-dropdown-menu
);

// Dropdown Action

// @deprecated as of v3.x use map $dropdown-action instead

$dropdown-action-toggle-font-size: 1rem !default; // 16px

/// @deprecated as of v3.x use map $dropdown-action instead

$dropdown-action-toggle-disabled-opacity: $component-disabled-opacity !default;

$dropdown-action-toggle-size: if(
	variable-exists(btn-monospaced-size-sm),
	$btn-monospaced-size-sm,
	2rem
) !default;

$dropdown-action: () !default;
$dropdown-action: map-deep-merge(
	(
		dropdown-toggle: (
			font-size: $dropdown-action-toggle-font-size,
			height: $dropdown-action-toggle-size,
			line-height: $dropdown-action-toggle-size,
			width: $dropdown-action-toggle-size,
			disabled: (
				opacity: $dropdown-action-toggle-disabled-opacity,
			),
		),
	),
	$dropdown-action
);
