$enable-scaling-navbar: $enable-scaling-components !default;

$navbar-border-radius: null !default;
$navbar-font-size: null !default;
$navbar-padding-x: 1rem !default;
$navbar-padding-y: 0.5rem !default;

$navbar-nav-link-padding-x: 0.5rem !default;

$navbar-title-font-size: 1.25rem !default; // 20px
$navbar-title-font-weight: $font-weight-semi-bold !default;
$navbar-title-margin-bottom: 0 !default;
$navbar-title-text-transform: null !default;

$navbar-text-truncate-spacer-right: 1.5625rem !default; // 25px
$navbar-text-truncate-max-width: 12.5rem !default; // 200px

$navbar-text-truncate: () !default;
$navbar-text-truncate: map-deep-merge(
	(
		display: inline-block,
		max-width: 100%,
		overflow: hidden,
		text-decoration: inherit,
		text-overflow: ellipsis,
		vertical-align: bottom,
		white-space: nowrap,
	),
	$navbar-text-truncate
);

// Navbar Brand

$navbar-brand-font-size: $font-size-lg !default;

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
// This is a string so we don't get nested calcs in `$navbar-brand-height` and `$navbar-brand-padding-y`

$nav-link-height: '#{$font-size-base} * #{$line-height-base} + #{$nav-link-padding-y} * 2' !default;
$navbar-brand-height: calc(
	#{$navbar-brand-font-size} * #{$line-height-base}
) !default;
$navbar-brand-padding-y: calc(
	(#{$nav-link-height} - #{$navbar-brand-height}) * 0.5
) !default;

// Navbar Toggler

$navbar-toggler-border-radius: $border-radius !default;
$navbar-toggler-cursor: null !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-padding-y: 0.25rem !default;

// Navbar Underline

$navbar-underline-active-bg: $component-active-bg !default;

$navbar-underline: () !default;
$navbar-underline: map-deep-merge(
	(
		navbar-toggler-link: (
			after: (
				background-color: $navbar-underline-active-bg,
				bottom: math-sign($navbar-padding-y),
				content: '',
				display: block,
				height: 0.1875rem,
				left: 0,
				position: absolute,
				right: 0,
				width: auto,
			),
		),
		media-breakpoint-up: (
			md: (
				navbar-expand-md: (
					navbar-underline: (
						navbar-nav: (
							nav-link: (
								active-class: (
									after: (
										background-color:
											$navbar-underline-active-bg,
										bottom: math-sign($navbar-padding-y),
										content: '',
										display: block,
										height: 0.1875rem,
										left: 0,
										position: absolute,
										right: 0,
										width: auto,
									),
								),
							),
						),
					),
				),
			),
		),
	),
	$navbar-underline
);

// Navbar Dark

$navbar-dark-color: rgba($white, 0.5) !default;

$navbar-dark-hover-color: rgba($white, 0.75) !default;

$navbar-dark-active-color: $white !default;

$navbar-dark-disabled-color: rgba($white, 0.25) !default;

$navbar-dark-brand-color: $navbar-dark-active-color !default;

$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;

$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;

// Navbar Light

$navbar-light-color: rgba($black, 0.5) !default;

$navbar-light-hover-color: rgba($black, 0.7) !default;

$navbar-light-active-color: rgba($black, 0.9) !default;

$navbar-light-disabled-color: rgba($black, 0.3) !default;

$navbar-light-brand-color: $navbar-light-active-color !default;

$navbar-light-brand-hover-color: $navbar-light-active-color !default;

$navbar-light-toggler-border-color: rgba($black, 0.1) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
