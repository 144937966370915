/// @deprecated as of v3.x with no replacement

$spinner-border-width: 0.25em !default;

/// @deprecated as of v3.x with no replacement

$spinner-width: 2rem !default;

/// @deprecated as of v3.x with no replacement

$spinner-height: $spinner-width !default;

// Spinner Sm

/// @deprecated as of v3.x with no replacement

$spinner-border-width-sm: 0.2em !default;

/// @deprecated as of v3.x with no replacement

$spinner-width-sm: 1rem !default;

/// @deprecated as of v3.x with no replacement

$spinner-height-sm: $spinner-width-sm !default;
